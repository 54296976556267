import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown.tsx";
import { Button } from "../../ui/button.tsx";
import { ChevronDown } from "lucide-react";

const quarters = ["Q1", "Q2", "Q3", "Q4"];
const years = ["FY 2024-2025", "FY 2023-2024", "FY 2022-2023", "FY 2021-2022"]; // Can be dynamic based on requirements

export const ConcallHeader = ({ selectedQuarter, selectedFY, onQuarterChange, onFYChange }) => {
  return (
    <div className="sticky top-0 z-10 bg-[#000300] border-b border-neutral-700 p-4 flex items-center gap-4">
      <div className="flex items-center gap-2">
        <span className="text-sm text-neutral-400">Quarter:</span>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm">
              {selectedQuarter}
              <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {quarters.map((q) => (
              <DropdownMenuItem
                key={q}
                onClick={() => onQuarterChange(q)}
              >
                {q}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="flex items-center gap-2">
        <span className="text-sm text-neutral-400">Financial Year:</span>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm">
              {selectedFY}
              <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {years.map((y) => (
              <DropdownMenuItem
                key={y}
                onClick={() => onFYChange(y)}
              >
                {y}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}; 