import React from "react";
import { useSearchParams } from "react-router-dom";
import GovtNewsList from "./components/GovtNews/GovtNewsList.jsx";
import AnnouncementsTable from "./components/CompanyAnnouncements/AnnouncementsTable.jsx";
import ComingSoon from "./components/News/ComingSoon.jsx";
import ComingSoonGovtNews from "./components/GovtNews/ComingSoon.jsx";

const Feed = () => {
  const [searchParams] = useSearchParams();
  const activeSection = searchParams.get('section') || 'govt';

  const renderContent = () => {
    switch (activeSection) {
      case "govt":
        return <ComingSoonGovtNews />;
      case "announcements":
        return <AnnouncementsTable />;
      case "news":
        return <ComingSoon />;
      default:
        return <ComingSoonGovtNews />;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="border-b border-neutral-700 p-4 flex justify-between items-center">
        <h1 className="text-2xl font-mono">Feed</h1>
      </div>
      <div className="flex-1 overflow-auto">
        {renderContent()}
      </div>
    </div>
  );
};

export default Feed;