import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs.tsx";
import { ConcallHeader } from "./components/ConcallHeader";
import { ConcallSummary } from "./components/ConcallSummary";
import { ConversationSummaries } from "./components/ConversationSummaries";
import { ConcallDocument } from "./components/ConcallDocument";
import { fetchConcallSummary, fetchConversationSummaries, fetchConcallDocument } from "./api/concallApi";

export const ConcallContent = () => {
  const { ticker } = useParams();
  const [quarter, setQuarter] = useState("Q1");
  const [fy, setFY] = useState("FY 2024-2025");

  const { data: summaryData, isLoading: isSummaryLoading, error: summaryError } = useQuery({
    queryKey: ["concall-summary", ticker, quarter, fy],
    queryFn: () => fetchConcallSummary(ticker, quarter, fy),
    retry: false
  });

  const { data: conversationsData, isLoading: isConversationsLoading, error: conversationsError } = useQuery({
    queryKey: ["concall-conversations", ticker, quarter, fy],
    queryFn: () => fetchConversationSummaries(ticker, quarter, fy),
    retry: false
  });

  const { data: documentData, isLoading: isDocumentLoading, error: documentError } = useQuery({
    queryKey: ["concall-document", ticker, quarter, fy],
    queryFn: () => fetchConcallDocument(ticker, quarter, fy),
    retry: false
  });

  const NoDataMessage = () => (
    <div className="flex flex-col items-center justify-center h-full text-center p-6">
      <p className="text-neutral-400 mb-2">No concall data available for {ticker} in {quarter} {fy}</p>
      <p className="text-sm text-neutral-500">Try selecting a different quarter or financial year</p>
    </div>
  );

  return (
    <div className="flex flex-col h-full">
      <ConcallHeader
        selectedQuarter={quarter}
        selectedFY={fy}
        onQuarterChange={setQuarter}
        onFYChange={setFY}
      />
      
      <Tabs defaultValue="summary" className="flex flex-col flex-1 min-h-0">
        <TabsList className="border-b border-neutral-700">
          <TabsTrigger value="summary">Concall Summary</TabsTrigger>
          <TabsTrigger value="conversations">Conversation Summaries</TabsTrigger>
          <TabsTrigger value="document">Concall Document</TabsTrigger>
        </TabsList>

        <TabsContent value="summary" className="flex-1 min-h-0">
          <ConcallSummary 
            data={summaryData} 
            isLoading={isSummaryLoading} 
            error={summaryError}
            NoDataComponent={NoDataMessage}
          />
        </TabsContent>

        <TabsContent value="conversations" className="flex-1 min-h-0">
          <ConversationSummaries 
            data={conversationsData} 
            isLoading={isConversationsLoading} 
            error={conversationsError}
            NoDataComponent={NoDataMessage}
          />
        </TabsContent>

        <TabsContent value="document" className="flex-1 min-h-0">
          <ConcallDocument 
            data={documentData} 
            isLoading={isDocumentLoading} 
            error={documentError}
            NoDataComponent={NoDataMessage}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
}; 