import React from "react";
import { MessageSquarePlus } from "lucide-react";

const FollowUpQueries = ({ queries, onQueryClick }) => {
  if (!queries || queries.length === 0) return null;

  return (
    <div className="mt-4 border-t border-terminal-dim pt-4">
      <div className="flex items-center gap-2 mb-2">
        <MessageSquarePlus className="w-4 h-4 text-terminal-blue" />
        <span className="text-sm font-mono text-terminal-blue">
          Follow-up questions
        </span>
      </div>
      <div className="flex flex-wrap gap-2">
        {queries.map((query, index) => (
          <button
            key={index}
            onClick={() => onQueryClick(query)}
            className="px-3 py-1.5 text-sm font-mono rounded-none 
                     border border-terminal-dim bg-terminal-bg 
                     text-terminal-blue hover:bg-terminal-dim 
                     transition-colors text-left"
          >
            {query}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FollowUpQueries;
