import {
  Calendar,
  Search,
  History,
  SquarePen,
  ChevronRight,
  Newspaper,
  Building2,
  Globe,
  MessageSquare,
  LogOut,
  Sparkles,
  ChevronLeft,
  Menu
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { LogoText } from "./Nav/Navbar";
import { useQuery } from "@tanstack/react-query";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
  SidebarSeparator,
  SidebarFooter,
} from "./ui/sidebar.tsx";
import { useAuth } from "../context/AuthContext.jsx";
import api from "../config/axios.js";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./ui/collapsible.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "./ui/button.tsx";
import { supabase } from "../supabaseClient.js";
import { useState } from 'react';

const mainNavItems = [
  {
    title: "New Chat",
    icon: SquarePen,
    url: "/chat",
  },
  {
    title: "Deep Dive",
    icon: Search,
    url: "/search",
  }
];

const newsItems = [
  {
    title: "Government News",
    icon: Globe,
    value: "govt",
  },
  {
    title: "Company Announcements",
    icon: Building2,
    value: "announcements",
  },
  {
    title: "News",
    icon: MessageSquare,
    value: "news",
  }
];

const fetchChatHistory = async () => {
  try {
    const { data } = await api.get("/chat/history");

    if (!data || data.length === 0) {
      return [];
    }

    const formattedSessions = data
      .map((session) => ({
        id: session.id,
        title: session.title || "Untitled Chat",
        created_at: session.created_at,
        updated_at: session.updated_at,
      }))
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    return formattedSessions;
  } catch (error) {
    return [];
  }
};

export default function AppSidebar() {
  const { session } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const searchParams = new URLSearchParams(location.search);
  const currentSection = searchParams.get('section') || 'govt';
  const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    data: chatHistory,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["chat-history"],
    queryFn: fetchChatHistory,
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
  });

  const handleNewChat = () => {
    queryClient.invalidateQueries(["chat-history"]);
    navigate("/chat", { replace: true });
  };

  const handleChatSelect = (sessionId) => {
    navigate(`/chat/${sessionId}`, { replace: true });
  };

  return (
    <>
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={`fixed top-4 ${isCollapsed ? 'left-4' : 'left-[15.5rem]'} z-50 p-2 rounded-lg bg-neutral-800 hover:bg-neutral-700 transition-all duration-300 md:flex items-center justify-center hidden`}
      >
        {isCollapsed ? (
          <Menu size={20} className="text-gray-200" />
        ) : (
          <ChevronLeft size={20} className="text-gray-200" />
        )}
      </button>

      <Sidebar className={`transition-all duration-300 flex flex-col h-screen ${isCollapsed ? 'w-0 -translate-x-full' : 'w-64 translate-x-0'}`}>
        <div className={`flex-1 flex flex-col overflow-hidden ${isCollapsed ? 'opacity-0 invisible' : 'opacity-100 visible'} transition-opacity duration-300`}>
          <SidebarHeader className="flex-shrink-0">
            <Link to="/" className="">
              <h1 className="text-2xl font-bold text-white mt-2 px-2" style={{ fontFamily: "Poppins" }}>
                <LogoText>CompoundingAI</LogoText>
              </h1>
            </Link>
          </SidebarHeader>

          <SidebarContent className="flex-1 overflow-y-auto">
            <SidebarGroup>
              <SidebarSeparator className="bg-neutral-800" />
              <SidebarGroupContent>
                <SidebarMenu>
                  {mainNavItems.map((item) => (
                    <SidebarMenuItem key={item.url}>
                      <SidebarMenuButton asChild>
                        <Link
                          to={item.url}
                          className="p-2 font-semibold mt-4 py-6 border border-neutral-800 rounded-2xl flex items-center gap-2"
                          onClick={item.url === "/chat" ? handleNewChat : undefined}
                        >
                          <item.icon className="w-5 h-5" />
                          <span>{item.title}</span>
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}

                  <SidebarMenuItem>
                    <Collapsible defaultOpen={location.pathname === '/feed'}>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton asChild className="p-2 py-5">
                          <div className="flex items-center">
                            <Newspaper className="w-5 h-5" />
                            <span className="ml-2">News</span>
                            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                          </div>
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        <SidebarMenuSub className="border-l-gray-800">
                          {newsItems.map((item) => (
                            <SidebarMenuSubItem key={item.value}>
                              <SidebarMenuButton
                                asChild
                                isActive={location.pathname === '/feed' && currentSection === item.value}
                              >
                                <Link
                                  to={`/feed?section=${item.value}`}
                                  className="p-2 py-5 flex items-center gap-2"
                                >
                                  <item.icon className="w-4 h-4" />
                                  <span>{item.title}</span>
                                </Link>
                              </SidebarMenuButton>
                            </SidebarMenuSubItem>
                          ))}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </Collapsible>
                  </SidebarMenuItem>
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>

            <SidebarGroup>
              <SidebarGroupContent className="gap-0">
                <SidebarMenu>
                  <SidebarMenuItem>
                    <Collapsible defaultOpen={false} className="group/collapsible">
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton asChild className="p-2 py-5">
                          <div className="flex items-center">
                            <History className="w-5 h-5" />
                            <span className="ml-2">History</span>
                            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                          </div>
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        {isLoading && <div>Loading chat history...</div>}
                        {isError && <div>Error loading chat history.</div>}
                        {chatHistory && chatHistory.length === 0 && (
                          <div>No chat history found.</div>
                        )}
                        <SidebarMenuSub className="border-l-gray-800 max-h-80 overflow-y-scroll">
                          {chatHistory &&
                            chatHistory.map((session) => (
                              <SidebarMenuSubItem key={session.id}>
                                <SidebarMenuButton asChild>
                                  <Link
                                    to={`/chat/${session.id}`}
                                    onClick={() => handleChatSelect(session.id)}
                                    className="p-2 py-5 flex items-center gap-2 font-mono"
                                  >
                                    <Calendar className="w-4 h-4" />
                                    <span>{session.title}</span>
                                  </Link>
                                </SidebarMenuButton>
                              </SidebarMenuSubItem>
                            ))}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </Collapsible>
                  </SidebarMenuItem>
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          </SidebarContent>

          <SidebarFooter className="flex-shrink-0 mt-auto border-t border-neutral-800">
            <div className="flex flex-col gap-2 p-4">
              {/* <div className="bg-gradient-to-r from-blue-400 to-blue-800 p-[0.08rem] rounded-lg">
                <Link to="/pricing" className="block">
                  <Button
                    className="w-full text-blue-400 font-medium hover:bg-indigo-600
                        hover:text-white bg-neutral-900 upgrade-border
                        rounded-[0.45rem] hover:bg-gradient-to-r from-blue-400
                        to-blue-800"
                  >
                    <Sparkles className="w-4 h-4 mr-2" />
                    Upgrade To Pro
                  </Button>
                </Link>
              </div> */}
              
              <Button
                onClick={() => {
                  supabase.auth.signOut();
                }}
                className="w-full bg-neutral-900 text-neutral-200 hover:bg-neutral-800"
              >
                <LogOut className="w-4 h-4 mr-2" />
                Sign Out
              </Button>
            </div>
          </SidebarFooter>
        </div>
      </Sidebar>
    </>
  );
}