import { useState } from "react";
import { Badge } from "../../ui/badge.tsx";
import { Skeleton } from "../../ui/skeleton.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../ui/dialog.tsx";
import { Rating } from "@mui/material";

export const ConversationSummaries = ({ data, isLoading, error, NoDataComponent }) => {
  const [selectedConversation, setSelectedConversation] = useState(null);

  if (isLoading) {
    return <Skeleton className="w-full h-full" />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-red-400 text-sm">Failed to load conversations. Please try again later.</p>
      </div>
    );
  }

  if (!data?.conversations?.length) {
    return <NoDataComponent />;
  }

  return (
    <>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto">
          <div className="p-6 space-y-6">
            {data?.conversations?.map((conversation) => (
              <div key={conversation.id} className="border border-neutral-800 rounded-lg p-4">
                <div className="flex items-start justify-between gap-4 mb-3">
                  <div>
                    <h3 className="text-lg font-medium text-neutral-200 mb-2">
                      {conversation.title}
                    </h3>
                    <div className="flex items-center gap-3">
                      <Badge className={`${
                        conversation.sentiment === 'positive' ? 'bg-green-500/20 text-green-500' :
                        conversation.sentiment === 'negative' ? 'bg-red-500/20 text-red-500' :
                        conversation.sentiment === 'cautionary' ? 'bg-yellow-500/20 text-yellow-500' :
                        'bg-blue-500/20 text-blue-500'
                      }`}>
                        {conversation.sentiment}
                      </Badge>
                      <div className="flex items-center gap-1">
                        <span className="text-sm text-neutral-400">Management Rating:</span>
                        <Rating value={conversation.managementRating} readOnly size="small" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prose prose-invert max-w-none">
                  <p className="text-sm text-neutral-300 whitespace-pre-line">
                    {conversation.summary}
                  </p>
                </div>
                <button
                  onClick={() => setSelectedConversation(conversation)}
                  className="mt-3 text-blue-400 hover:text-blue-300 text-sm"
                >
                  View full conversation →
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="sticky bottom-0 bg-[#000300] text-xs text-neutral-500 italic p-4 border-t border-neutral-700">
          Note: The summaries, sentiment analysis, and management ratings are AI-generated. Please conduct your own research and due diligence before making any decisions.
        </div>
      </div>

      <Dialog open={!!selectedConversation} onOpenChange={() => setSelectedConversation(null)}>
        <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{selectedConversation?.title}</DialogTitle>
          </DialogHeader>
          <div className="mt-4 text-sm text-neutral-200 whitespace-pre-line">
            {selectedConversation?.fullConversation}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}; 