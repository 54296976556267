import React, { useEffect, useState } from 'react';
import api from '../config/axios';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

const PaymentButton = ({ amount, planTitle, buttonText }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        // Load Razorpay script only if user is authenticated
        if (localStorage.getItem('token')) {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.async = true;
            document.body.appendChild(script);
            
            return () => {
                document.body.removeChild(script);
            };
        }
    }, []);

    const handlePayment = async () => {
        // Check authentication first
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/register', { 
                state: { 
                    returnTo: '/pricing',
                    selectedPlan: planTitle 
                } 
            });
            return;
        }

        try {
            setLoading(true);
            
            // Step 1: Create order
            const orderResponse = await api.post('/create-order', {
                amount: amount,
                planTitle: planTitle,
                currency: "INR"
            });

            const { order_id, amount: orderAmount, currency, key } = orderResponse.data;

            // Step 2: Initialize Razorpay options
            const options = {
                key: key,
                amount: orderAmount,
                currency: currency,
                name: "CompoundingAI",
                description: `${planTitle} Subscription`,
                image: "/new_logo_v1.png",
                order_id: order_id,
                handler: async function (response) {
                    try {
                        console.log("Payment successful, verifying...", response);
                        const verifyResponse = await api.post(
                            '/verify-payment',
                            {
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_signature: response.razorpay_signature
                            },
                            {
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    'Content-Type': 'application/json'
                                }
                            }
                        );

                        if (verifyResponse.data.status === 'success') {
                            alert('Payment successful! Redirecting to dashboard...');
                            window.location.href = verifyResponse.data.redirect_url;
                        } else {
                            throw new Error(verifyResponse.data.message || 'Payment verification failed');
                        }
                    } catch (error) {
                        console.error('Payment verification failed:', error);
                        console.error('Error details:', error.response?.data);
                        alert(`Payment verification failed: ${error.response?.data?.detail || error.message}`);
                        setLoading(false);
                    }
                },
                prefill: {
                    email: localStorage.getItem('userEmail') || '',
                },
                theme: {
                    color: "#6366F1"
                },
                modal: {
                    ondismiss: function() {
                        setLoading(false);
                    }
                },
                "modal": {
                    "ondismiss": function() {
                        setLoading(false);
                    }
                },
                "notes": {
                    "plan_name": planTitle
                },
                "on_payment_failed": function(response) {
                    alert("Payment failed. Please try again.");
                    console.error("Payment failed:", response.error);
                    setLoading(false);
                }
            };

            // Create Razorpay instance and open payment modal
            const razorpay = new window.Razorpay(options);
            
            // Handle any potential errors during initialization
            razorpay.on('payment.failed', function(response){
                console.error('Payment failed:', response.error);
                alert(`Payment failed: ${response.error.description}`);
                setLoading(false);
            });

            razorpay.open();

        } catch (error) {
            setLoading(false);
            
            if (error.response?.status === 401) {
                // Token expired or invalid
                localStorage.removeItem('token');
                navigate('/register', { 
                    state: { 
                        returnTo: '/pricing',
                        selectedPlan: planTitle 
                    } 
                });
            } else {
                console.error('Payment initiation failed:', error);
                alert('Unable to initiate payment. Please try again.');
            }
        }
    };

    const StyledButton = styled(Button)(({ theme }) => ({
        background: theme.palette.button.primary,
        color: 'white',
        width: '100%',
        padding: '12px 24px',
        borderRadius: '8px',
        transition: 'all 0.3s ease',
        '&:hover': {
            background: theme.palette.button.hover,
            transform: 'translateY(-2px)',
            boxShadow: `0 4px 12px ${theme.palette.custom.glow}`,
        },
        '&:disabled': {
            background: theme.palette.action.disabledBackground,
            boxShadow: 'none',
            transform: 'none',
        },
    }));

    return (
        <StyledButton
            onClick={handlePayment}
            disabled={loading}
            variant="contained"
            fullWidth
        >
            {loading ? (
                <span className="inline-flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
                         xmlns="http://www.w3.org/2000/svg" 
                         fill="none" 
                         viewBox="0 0 24 24"
                         width="20"
                         height="20">
                        <circle className="opacity-25" cx="12" cy="12" r="10" 
                                stroke="currentColor" strokeWidth="4"/>
                        <path className="opacity-75" fill="currentColor" 
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    Processing...
                </span>
            ) : (
                buttonText
            )}
        </StyledButton>
    );
};

export default PaymentButton;