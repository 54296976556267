import React from "react";
import { Helmet } from 'react-helmet';
import Hero from "./Hero";
import Features from "./Features";
import Analytics from "./Analytics";
import Cards from "./Cards/Cards";
import Pricing from "./Pricing";

const Home = () => (
  <>
    <Helmet>
      <title>CompoundingAI - AI-Powered Financial Analytics Platform</title>
      <meta name="description" content="Transform your investment research with AI-powered financial analytics, earnings call summaries, and deep dive reports." />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "CompoundingAI",
            "url": "https://compoundingai.in",
            "description": "AI-Powered Financial Analytics Platform",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://compoundingai.in/search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
        `}
      </script>
    </Helmet>
    <section id="home">
      <Hero />
    </section>
    <section id="features">
      <Features />
    </section>
    {/* <section id="demo">
      <Analytics />
    </section> */}
    {/* <section id="pricing">
      <Pricing />
    </section> */}
  </>
);

export default Home;
