import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Skeleton } from "../../ui/skeleton.tsx";
import { Button } from "../../ui/button.tsx";
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from 'lucide-react';

// Import and set up PDF.js worker
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Add inline styles for PDF layers
const pdfStyles = {
  annotationLayer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  textLayer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    opacity: 0.2,
    lineHeight: 1.0,
  }
};

export const ConcallDocument = ({ data, isLoading }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [error, setError] = useState(null);

  const onDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error);
    setError('Unable to load PDF document. Please try again later.');
  };

  if (isLoading) {
    return <Skeleton className="w-full h-full" />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-neutral-400 text-sm">{error}</p>
      </div>
    );
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const adjustZoom = (delta) => {
    setScale(prevScale => Math.max(0.5, Math.min(2.0, prevScale + delta)));
  };

  return (
    <div className="flex flex-col h-full">
      <div className="sticky top-0 z-10 bg-[#000300] border-b border-neutral-700 p-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={previousPage}
            disabled={pageNumber <= 1}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <span className="text-sm text-neutral-400">
            Page {pageNumber} of {numPages}
          </span>
          <Button
            variant="outline"
            size="sm"
            onClick={nextPage}
            disabled={pageNumber >= numPages}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
        
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => adjustZoom(-0.1)}
          >
            <ZoomOut className="h-4 w-4" />
          </Button>
          <span className="text-sm text-neutral-400">
            {Math.round(scale * 100)}%
          </span>
          <Button
            variant="outline"
            size="sm"
            onClick={() => adjustZoom(0.1)}
          >
            <ZoomIn className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="flex-1 overflow-auto p-4 flex justify-center">
        <Document
          file={data?.pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={<Skeleton className="w-[595px] h-[842px]" />}
        >
          <Page
            pageNumber={pageNumber}
            scale={scale}
            loading={<Skeleton className="w-[595px] h-[842px]" />}
          />
        </Document>
      </div>

      <div className="text-xs text-neutral-500 italic p-4 border-t border-neutral-700">
        Note: This document is the original earnings call transcript. Please refer to the summaries for AI-generated insights.
      </div>
    </div>
  );
}; 