import React from "react";
import { useQuery } from "@tanstack/react-query";
import { marked } from "marked";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { supabase } from "../../supabaseClient";

const fetchBlogs = async () => {
  const { data, error } = await supabase
    .from('blogs')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
};

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const Blogs = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["blogs"],
    queryFn: fetchBlogs,
  });
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="flex justify-center min-h-96 items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center min-h-96 items-center">
        <h2 className="text-center text-gray-200 text-2xl font-semibold">
          Oops... Error Loading Blogs
        </h2>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>CompoundingAI Blog - Financial Research & Insights</title>
        <meta 
          name="description" 
          content="Explore in-depth articles about financial markets, investment strategies, and AI-powered research tools." 
        />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-white mb-8">Latest Insights</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
          {data?.map((blog) => (
            <div
              key={blog.id}
              className="bg-gray-900 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 cursor-pointer"
              onClick={() => navigate(`/blogs/${blog.id}`)}
            >
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src={blog.image_url}
                  alt={blog.title}
                  className="object-cover w-full h-48"
                />
              </div>
              <div className="p-6">
                <h2 className="text-xl font-semibold text-white mb-2 line-clamp-2">
                  {blog.title}
                </h2>
                <p className="text-gray-400 text-sm line-clamp-3">
                  {stripHtmlTags(marked(blog.content))}
                </p>
                <div className="mt-4 text-sm text-gray-500">
                  {new Date(blog.created_at).toLocaleDateString()}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;
