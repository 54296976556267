import React, { useState } from "react";
import { Card, CardContent, CardActions, Typography } from "@mui/material";
import PaymentButton from "./PaymentButton";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useAuth } from '../context/AuthContext';
import ContactModal from './ContactModal';

const pricingPlans = [
  {
    title: "Free",
    monthlyPrice: "₹0",
    yearlyPrice: "₹0",
    monthlyAmount: 0,
    yearlyAmount: 0,
    features: ["5 Companies Deep Dives", "10 copilot queries"],
    buttonText: "Start Now",
    upgradeText: "Current Plan",
    isFreePlan: true,
    popular: false,
  },
  {
    title: "Enthusiast",
    monthlyPrice: "₹999",
    yearlyPrice: "₹9,999",
    monthlyAmount: 999,
    yearlyAmount: 9999,
    features: ["30 Companies Deep Dives", "400 copilot queries per month"],
    buttonText: "Try Enthusiast",
    upgradeText: "Upgrade to Enthusiast",
    isFreePlan: false,
    popular: false,
  },
  {
    title: "Pro",
    monthlyPrice: "₹1,799",
    yearlyPrice: "₹17,999",
    monthlyAmount: 1799,
    yearlyAmount: 17999,
    features: [
      "100 Companies Deep Dives",
      "1000 copilot queries per month",
      "Priority access to new features",
    ],
    buttonText: "Go Pro",
    upgradeText: "Upgrade to Pro",
    isFreePlan: false,
    popular: true,
  },
  {
    title: "Enterprise",
    monthlyPrice: "",
    yearlyPrice: "",
    features: [
      "Unlimited companies Deep Dives",
      "Unlimited copilot queries",
      "Custom integrations",
      "Dedicated support",
      "Custom features"
    ],
    buttonText: "Contact Us",
    isEnterprise: true,
    popular: false,
  }
];

const PricingCard = styled(Card)(({ theme, isPopular }) => ({
  background: theme.palette.gradients.card,
  border: isPopular
    ? `2px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.custom.border}`,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
  position: "relative",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: `0 12px 24px ${theme.palette.custom.glow}`,
  },
}));

const PopularRibbon = styled('div')({
  position: 'absolute',
  top: '-5px',
  right: '-5px',
  overflow: 'hidden',
  width: '150px',
  height: '150px',
  zIndex: 2,
  pointerEvents: 'none',
  '& > div': {
    position: 'absolute',
    display: 'block',
    width: '225px',
    padding: '15px 0',
    backgroundColor: '#4F46E5',
    color: 'white',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '0.75rem',
    transform: 'rotate(45deg)',
    right: '-56px',
    top: '32px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  }
});

const PricingPage = () => {
  const navigate = useNavigate();
  const { user, userSubscription } = useAuth();
  const [billingPeriod, setBillingPeriod] = React.useState('annually');
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const BillingToggle = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '2rem',
    '& button': {
      padding: '8px 24px',
      borderRadius: '24px',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      fontSize: '1rem',
      fontWeight: 500,
    }
  });

  const handleFreePlan = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register", {
        state: {
          returnTo: "/pricing",
          selectedPlan: "Free",
        },
      });
    } else {
      navigate("/chat");
    }
  };

  const getButtonText = (plan) => {
    if (!user) return plan.buttonText;
    if (plan.isEnterprise) return plan.buttonText;
    if (userSubscription?.tier === plan.title) return "Current Plan";
    return plan.upgradeText;
  };

  const getPrice = (plan) => {
    if (plan.isEnterprise) return null;
    return billingPeriod === 'annually' ? plan.yearlyPrice : plan.monthlyPrice;
  };

  const getAmount = (plan) => {
    if (plan.isEnterprise) return null;
    return billingPeriod === 'annually' ? plan.yearlyAmount : plan.monthlyAmount;
  };

  const handleEnterpriseContact = () => {
    setContactModalOpen(true);
  };

  return (
    <Box
      sx={(theme) => ({
        minHeight: "100vh",
        background: theme.palette.gradients.dark,
        py: 6,
        px: { xs: 4, sm: 6, lg: 8 },
      })}
    >
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Simple, Transparent Pricing
          </h2>
          <p className="mt-4 text-xl text-gray-300">
            Choose the perfect plan for your investment journey
          </p>
          
          <BillingToggle className="mt-8">
            <button
              onClick={() => setBillingPeriod('annually')}
              style={{
                backgroundColor: billingPeriod === 'annually' ? '#4F46E5' : 'transparent',
                color: billingPeriod === 'annually' ? 'white' : '#9CA3AF',
                border: `1px solid ${billingPeriod === 'annually' ? '#4F46E5' : '#4B5563'}`
              }}
            >
              Annually
            </button>
            <button
              onClick={() => setBillingPeriod('monthly')}
              style={{
                backgroundColor: billingPeriod === 'monthly' ? '#4F46E5' : 'transparent',
                color: billingPeriod === 'monthly' ? 'white' : '#9CA3AF',
                border: `1px solid ${billingPeriod === 'monthly' ? '#4F46E5' : '#4B5563'}`
              }}
            >
              Monthly
            </button>
          </BillingToggle>
        </div>
        <div className="mt-12 grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {pricingPlans.map((plan) => (
            <PricingCard
              key={plan.title}
              isPopular={plan.popular}
            >
              {plan.popular && (
                <PopularRibbon>
                  <div>Most Popular</div>
                </PopularRibbon>
              )}
              <CardContent
                sx={{
                  flexGrow: 1,
                  p: 3,
                }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  className="text-2xl font-bold text-white"
                >
                  {plan.title}
                </Typography>
                <div className="mt-4 flex items-baseline text-white">
                  {!plan.isEnterprise ? (
                    <>
                      <span className="text-5xl font-extrabold tracking-tight">
                        {getPrice(plan)}
                      </span>
                      <span className="ml-1 text-xl font-medium text-gray-400">
                        /{billingPeriod === 'annually' ? 'year' : 'month'}
                      </span>
                    </>
                  ) : (
                    <span className="text-xl font-medium text-gray-400">
                      Contact us for pricing
                    </span>
                  )}
                </div>
                <ul className="mt-6 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-6 w-6 text-indigo-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-base text-gray-300">{feature}</p>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardActions
                sx={{
                  p: 3,
                  pt: 0,
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "auto",
                }}
              >
                {plan.isEnterprise ? (
                  <button
                    onClick={handleEnterpriseContact}
                    className="w-full py-3 px-6 border border-transparent text-base font-medium rounded-md 
                      text-white bg-indigo-600 hover:bg-indigo-700 transition-all duration-200 
                      hover:transform hover:-translate-y-1 hover:shadow-lg"
                  >
                    {plan.buttonText}
                  </button>
                ) : plan.isFreePlan ? (
                  <button
                    onClick={handleFreePlan}
                    className="w-full py-3 px-6 border border-transparent text-base font-medium rounded-md 
                      text-white bg-indigo-600 hover:bg-indigo-700 transition-all duration-200 
                      hover:transform hover:-translate-y-1 hover:shadow-lg"
                  >
                    {getButtonText(plan)}
                  </button>
                ) : (
                  <PaymentButton
                    amount={getAmount(plan)}
                    planTitle={plan.title}
                    buttonText={getButtonText(plan)}
                    billingPeriod={billingPeriod}
                  />
                )}
              </CardActions>
            </PricingCard>
          ))}
        </div>
      </div>
      <ContactModal
        open={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
      />
    </Box>
  );
};

export default PricingPage;
