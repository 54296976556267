import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: theme.palette.gradients.dark,
  color: theme.palette.common.white,
  position: "relative",
  overflow: "hidden",
  marginTop: 0,
  paddingTop: 0,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at center, ${theme.palette.custom.glow} 0%, transparent 70%)`,
    animation: "pulse 4s ease-in-out infinite",
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  height: "auto",
  margin: "0 auto",
  marginBottom: "2rem",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
  [theme.breakpoints.up("md")]: {
    textShadow: "3px 3px 6px rgba(0,0,0,0.5)",
  },
  
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.button.primary,
  color: 'white',
  padding: '12px 32px',
  fontSize: '1.125rem',
  fontWeight: 500,
  marginTop: '-16px',
  '&:hover': {
    background: theme.palette.button.hover,
    transform: 'translateY(-2px)',
    boxShadow: `0 6px 20px ${theme.palette.custom.glow}`,
  },
}));

// Add keyframes for the pulse animation
const keyframes = {
  '@keyframes pulse': {
    '0%': {
      opacity: 0.5,
    },
    '50%': {
      opacity: 0.8,
    },
    '100%': {
      opacity: 0.5,
    },
  },
};

const Hero = () => {
  return (
    <StyledBox>
      <Container maxWidth="md" sx={{ pt: '70px' }}>
        <Grid container direction="column" alignItems="center" spacing={4}>
          <Grid item xs={12}>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              <StyledImage src="/new_logo_v1.png" alt="Logo" />
            </motion.div>
          </Grid>
          <Grid item>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <StyledTypography variant="h2" fontWeight="bold" align="center">
                Your AI-powered research assistant
              </StyledTypography>
            </motion.div>
          </Grid>
          <Grid item>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <Typography 
                variant="h5" 
                sx={{ 
                  color: '#40E0D0',
                  fontWeight: 600,
                  textAlign: 'center',
                  marginBottom: 4
                }}
              >
                Maximizing shareholder value using AI
              </Typography>
            </motion.div>
          </Grid>
          <Grid item>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <StyledButton
                component={RouterLink}
                to="/contact-sales"
                variant="contained"
              >
                Get Started
              </StyledButton>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default Hero;
