import React from "react";
import { MessagesSquare } from "lucide-react";

const ComingSoonGovtNews = () => {
  return (
    <div className="flex-1 flex items-center justify-center">
      <div className="text-center">
        <MessagesSquare className="w-16 h-16 mx-auto mb-4 text-neutral-500" />
        <h2 className="text-2xl font-semibold text-neutral-300 mb-2">
          Coming Soon
        </h2>
        <p className="text-neutral-500">
          Government news integration is under development
        </p>
      </div>
    </div>
  );
};

export default ComingSoonGovtNews; 