import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import axios from "axios";
import styled from "@emotion/styled";
export const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: "calc(100vh - 70px)", // Account for navbar
  display: "flex",
  alignItems: "center",
  background: theme.palette.gradients.dark,
  padding: theme.spacing(8, 2),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: "#4F46E5", // Indigo-600
  color: "white",
  padding: "12px 24px",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  "&:hover": {
    background: "#4338CA", // Indigo-700
    transform: "translateY(-2px)",
    boxShadow: `0 4px 12px ${theme.palette.custom.glow}`,
  },
}));

const Waitlist = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    try {
      const response = await axios.post(
        "https://compoundingai.in/api/join_waitlist",
        { email },
      );
      setMessage(response.data.message);
      setEmail("");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // This is for the case where the email already exists
        setMessage(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Join the Waitlist
        </Typography>
        {message && (
          <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
            {message}
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Join Waitlist
          </StyledButton>
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default Waitlist;

