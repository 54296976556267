import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import analyticsImage from "../assets/analytics.jpg"; // Import the image
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";

const StyledSection = styled(Box)(({ theme }) => ({
  background: theme.palette.gradients.dark,
  padding: theme.spacing(12, 0),
  color: theme.palette.common.white,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: `linear-gradient(90deg, transparent, ${theme.palette.custom.border}, transparent)`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.button.primary,
  color: 'white',
  padding: '12px 32px',
  borderRadius: '8px',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: theme.palette.button.hover,
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 12px ${theme.palette.custom.glow}`,
  },
}));

const AnimatedImage = styled(motion.img)({
  width: "100%",
  maxWidth: 500,
  height: "auto",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(99, 102, 241, 0.2)",
  transition: "all 0.3s ease",
  '&:hover': {
    transform: "scale(1.02)",
    boxShadow: "0 12px 40px rgba(99, 102, 241, 0.3)",
  },
});

const Analytics = () => {
  return (
    <StyledSection>
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <AnimatedImage src={analyticsImage} alt="data analytics" />
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#6366F1",
                  fontWeight: 600,
                  marginBottom: 2
                }}
              >
                AI ANALYTICS DASHBOARD
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  marginBottom: 3
                }}
              >
                AI based insights at your fingertips
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.125rem",
                  lineHeight: 1.7,
                  color: "rgba(255, 255, 255, 0.9)",
                  marginBottom: 4
                }}
              >
                Do you find concalls and announcements hard to manage? Access
                our AI based insights dashboard to skim through the latest
                announcements and conference calls in minutes.
              </Typography>
              <StyledButton
                component={RouterLink}
                to="/waitlist"
                variant="contained"
                sx={{
                  background: '#4F46E5', // Indigo-600
                  '&:hover': {
                    background: '#4338CA', // Indigo-700
                  }
                }}
              >
                Join the Waitlist
              </StyledButton>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  );
};

export default Analytics;
