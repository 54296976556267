import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "./context/AuthContext";
import Navbar from "./components/Nav/Navbar";
import Home from "./components/Home";
import Login from "./components/Login";
import Waitlist from "./components/Waitlist";
import Register from "./components/Register";
import SearchPage from "./components/SearchPage";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import Chat from "./components/Chat";
import ChatHistory from "./components/ChatHistory";
import Blogs from "./components/Blog/Blogs";
import BlogSingle from "./components/Blog/BlogSingle";
import Editor from "./components/Blog/Editor";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PricingPage from "./components/Pricing";
import DeepDive from "./components/DeepDive/DeepDive";
import Search from "./components/Search/Search";
import Feed from "./components/Feed/Feed";
import GoogleAnalytics from './components/GoogleAnalytics';
import ContactModal from './components/ContactModal';
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import ResetPassword from "./components/ResetPassword";
import UpdatePassword from './components/UpdatePassword';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import Dashboard from "./components/Admin/Dashboard";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#40E0D0",
			light: "#65E5D9",
			dark: "#2DACA0",
		},
		secondary: {
			main: "#6366F1",
			light: "#818CF8",
			dark: "#4F46E5",
		},
		background: {
			default: "#0A0A0A",
			paper: "#1A1A1A",
		},
		gradients: {
			primary: "linear-gradient(45deg, #40E0D0, #6366F1)",
			dark: "linear-gradient(135deg, #0A0A0A 0%, #1A1A1A 100%)",
			card: "linear-gradient(to bottom, #1A1A1A, #2D2D2D)",
		},
		custom: {
			border: "rgba(99, 102, 241, 0.2)",
			glow: "rgba(64, 224, 208, 0.2)",
		},
		button: {
			primary: "#4F46E5",
			hover: "#4338CA",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "8px",
					textTransform: "none",
					transition: "all 0.3s ease",
				},
			},
		},
	},
});
const queryClient = new QueryClient();

function App() {
	const location = useLocation();
	const navigate = useNavigate();
	const isPrivateRoute = [
		"chat",
		"history",
		"chat/:sessionId",
		"deepdive",
		"search",
		"feed",
		"admin/dashboard"
	].includes(location.pathname.split("/")[1] === "admin" ? 
		`${location.pathname.split("/")[1]}/${location.pathname.split("/")[2]}` : 
		location.pathname.split("/")[1]);

	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<ThemeProvider theme={theme}>
					<Helmet>
						<title>CompoundingAI - AI-Powered Financial Analytics</title>
						<meta name="description" content="Access AI-powered financial analytics, earnings call summaries, and deep dive reports for smarter investment decisions." />
						<meta name="robots" content="index, follow" />
						<link rel="canonical" href="https://compoundingai.in" />
					</Helmet>
					<CssBaseline />
					<div className="App">
						<GoogleAnalytics />
						{!isPrivateRoute && <Navbar />}
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/waitlist" element={<Waitlist />} />
							<Route path="/login" element={<Login />} />
							<Route path="/register" element={<Register />} />
							<Route path="/pricing" element={<PricingPage />} />
							<Route
								path="/history"
								element={
									<PrivateRoute>
										<ChatHistory />
									</PrivateRoute>
								}
							/>
							<Route
								path="/chat"
								element={
									<PrivateRoute>
										<Chat />
									</PrivateRoute>
								}
							/>
							<Route
								path="/deepdive/:ticker"
								element={
									<PrivateRoute>
										<DeepDive />
									</PrivateRoute>
								}
							/>
							<Route
								path="/search"
								element={
									<PrivateRoute>
										<Search />
									</PrivateRoute>
								}
							/>
							<Route
								path="/feed"
								element={
									<PrivateRoute>
										<Feed />
									</PrivateRoute>
								}
							/>
							<Route path="/blogs" element={<Blogs />} />
							<Route path="/blogs/:blogId" element={<BlogSingle />} />
							<Route path="/blogs/editor" element={<Editor />} />
							<Route
								path="/chat/:sessionId"
								element={
									<PrivateRoute>
										<Chat />
									</PrivateRoute>
								}
							/>
							<Route 
								path="/contact-sales" 
								element={
									<Box sx={{ 
										height: '100vh', 
										display: 'flex', 
										alignItems: 'center', 
										justifyContent: 'center',
										bgcolor: 'background.default'
									}}>
										<ContactModal open={true} onClose={() => navigate('/')} />
									</Box>
								} 
							/>
							<Route path="/terms" element={<Terms />} />
							<Route path="/privacy" element={<Privacy />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							<Route path="/update-password" element={<UpdatePassword />} />
							<Route
								path="/admin/dashboard"
								element={
									<PrivateRoute>
										<Dashboard />
									</PrivateRoute>
								}
							/>
						</Routes>
						{!isPrivateRoute && <Footer />}
					</div>
				</ThemeProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
}

export default App;
