import { ChevronRight } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible.tsx";
import { sections } from "./constants";

export const DeepDiveSidebar = ({ selected, onSelect }) => {
  return (
    <div className="w-64 border-r border-neutral-700 h-full overflow-y-auto bg-[#000300]">
      <div className="p-4 space-y-4">
        {/* Concall Section - Now First */}
        <button
          onClick={() => onSelect(sections.concall.value)}
          className={`w-full text-start p-2 hover:bg-neutral-800 transition-colors text-sm ${
            selected === sections.concall.value
              ? "text-blue-500"
              : "text-neutral-200"
          }`}
        >
          {sections.concall.title}
        </button>

        {/* Forensics Section - Now Second and Collapsed by Default */}
        <Collapsible defaultOpen={false}>
          <CollapsibleTrigger className="flex items-center justify-between w-full p-2 text-neutral-200 hover:text-neutral-100 transition-colors">
            <span className="text-base font-medium">{sections.forensics.title}</span>
            <ChevronRight className="w-4 h-4 transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
          </CollapsibleTrigger>
          <CollapsibleContent className="mt-1">
            {sections.forensics.subsections.map((subsection, index) => (
              <button
                key={subsection.value}
                onClick={() => onSelect(subsection.value)}
                className={`w-full text-start p-2 pl-4 hover:bg-neutral-800 transition-colors text-sm truncate ${
                  selected === subsection.value
                    ? "text-blue-500"
                    : "text-neutral-200"
                }`}
              >
                {index + 1}. {subsection.label}
              </button>
            ))}
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
}; 