import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
	console.error("Missing Supabase environment variables:", {
		url: !!supabaseUrl,
		key: !!supabaseAnonKey,
	});
}

export const supabase = createClient(supabaseUrl || "", supabaseAnonKey || "");

// Debug log to verify environment variables are loaded
console.log("Supabase initialization:", {
	hasUrl: !!supabaseUrl,
	hasKey: !!supabaseAnonKey,
});

