import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
  ButtonGroup,
} from "@mui/material";
import axios from "axios";
import { supabase } from "../supabaseClient";
import { Link } from "react-router-dom";
import { StyledButton, StyledContainer } from "./Waitlist";
import { StyledOutlinedButton } from "./Login";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
    if (error) console.error("Sign in with Google failed:", error.message);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        "https://compoundingai.in/api/register",
        {
          email,
          password,
        },
      );

      console.log("Registration response:", response.data);

      if (response.data.requires_verification) {
        setSuccess(true);
        // Wait for 3 seconds before redirecting to login
        setTimeout(() => {
          navigate("/login", {
            state: {
              message:
                "Registration successful! Please check your email for verification.",
            },
          });
        }, 3000);
      } else {
        // Direct login if no verification required
        navigate("/login", {
          state: {
            message: "Registration successful! Please log in.",
          },
        });
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setError(
        error.response?.data?.detail ||
          "Registration failed. Please try again.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            {error}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </StyledButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <hr className="w-full border-neutral-400" />
            <div className="text-neutral-400 text-sm font-medium relative -top-3 z-1 bg-[#111111] px-2">
              Login with
            </div>
            <ButtonGroup
              variant="outlined"
              aria-label="Basic button group"
              height="10rem"
              fullWidth
            >
              {
                <StyledOutlinedButton
                  onClick={signInWithGoogle}
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    padding: 1,
                  }}
                  className="border border-neutral-600 p-2 flex items-center gap-2 rounded-md w-full py-4"
                >
                  <img
                    src="/google_icon.svg"
                    alt="Google Logo"
                    className="red-50 w-5 h-5"
                  />
                  Sign in with Google
                </StyledOutlinedButton>
              }
            </ButtonGroup>
          </Box>
          <Link
            to="/login"
            className="flex items-center text-sm underline hover:text-blue-500 justify-center mt-2 text-teal-500"
          >
            {"Already have an account? Sign In"}
          </Link>
        </Box>
      </Box>

      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Registration successful! Please check your email for verification.
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default Register;
