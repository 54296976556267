import { Search } from "lucide-react";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import api from "../../config/axios";
import { useQuery } from "@tanstack/react-query";
import { Separator } from "../ui/separator.tsx";
import { Link } from "react-router-dom";
import { supabase } from "../../supabaseClient.js";

// Desc: Search input component
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
const SearchInput = () => {
  const fetchSuggestions = async (query) => {
    try {
      const { data: companies } = await supabase
        .from("companies")
        .select("*")
        .ilike("Security Name", `%${query}%`)
        // .textSearch("Security Name", query, { type: "plain" })
        .limit(10);
      setCachedData(companies);
      console.log("Companies: ", companies);
      return companies;
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(""); // For debounced value
  const [cachedData, setCachedData] = useState([]); // To keep last valid results

  // Update `debouncedSearch` after timeout
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);

    return () => clearTimeout(timer); // Cleanup timeout on every input change
  }, [search]);
  const handleSearch = (e) => {
    setSearch(e.target.value); // Update search input immediately
  };
  const [searchFocued, setSearchFocused] = useState(false);
  useLayoutEffect(() => {
    setSearchFocused(false);
  }, []);
  const { data, isLoading, isError } = useQuery({
    queryKey: ["search", debouncedSearch],
    queryFn: () => fetchSuggestions(debouncedSearch),
  });
  const inputRef = useRef(null);
  return (
    <div className="relative w-full">
      <div className="flex items-center  h-full w-full">
        <Search className="w-6 text-neutral-400 bg-neutral-900 h-full ps-2" />
        <input
          type="text"
          value={search}
          className="bg-neutral-900 text-neutral-100 placeholder-neutral-400  border-neutral-700 p-2 w-full focus:outline-none py-3"
          onChange={handleSearch}
          autoFocus={false}
          onFocus={() => setSearchFocused(true)}
          onBlur={(e) => {
            if (!e.relatedTarget || !e.relatedTarget.closest(".search-menu")) {
              console.log("Blur");
              setSearchFocused(false);
            }
          }}
          placeholder="Search..."
          ref={inputRef}
        />
      </div>

      <div
        className={`bg-neutral-900 ${inputRef.current && inputRef?.current.value && searchFocued ? "absolute" : "hidden"} w-[100%] border-t border-neutral-700 max-h-[50svh] overflow-scroll search-menu z-10`}
      >
        {cachedData && cachedData.length > 0 && (
          <ul className="bg-neutral-900 w-full">
            {cachedData.map((item) => (
              <>
                <Link
                  to={`/deepdive/${item["Security Id"]}`}
                  className="hover:text-neutral-400"
                  onClick={() => {
                    setSearchFocused(false);
                    setSearch(item["Security Id"]);
                    inputRef.current.blur();
                  }}
                >
                  <li
                    key={item["Security Id"]}
                    className="bg-neutral-900 p-2 border-b border-neutral-700"
                  >
                    {item["Security Name"]}
                  </li>
                </Link>
              </>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
