import React from "react";
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group.tsx";
import { Separator } from "../ui/separator.tsx";

export const categories = [
  {
    label: "Guidance Adherence",
    value: "guidance",
  },
  {
    label: "Financial Reporting Standards",
    value: "reporting",
  },
  {
    label: "Management Responses Check",
    value: "management",
  },
  {
    label: "Capital Allocation Strategies",
    value: "capital",
  },
  {
    label: "Operations & Strategies Execution",
    value: "operations",
  },
  {
    label: "Risk Management & External Factors",
    value: "risk",
  },
];

export const DeepDiveSelect = ({ selected, onSelect }) => {
  return (
    <div className="items-center text-start  min-w-36 h-full overflow-y-auto overflow-x-hidden">
      <h2 className="text-lg text-gray-100 font-semibold px-2 text-center w-full py-2">
        Categories
      </h2>
      <ToggleGroup type="single" className="flex-col">
        {categories.map((category, index) => (
          <>
            <div>
              <ToggleGroupItem
                key={category.value}
                value={category.value}
                className="h-full data-[state=on]:bg-none] font-medium data-[state=on]:text-blue-500 text-start hover:bg-neutral-900/0 rounded-none px-4 py-2"
                data-state={selected === category.value ? "on" : "off"}
                onClick={() => onSelect(category.value)}
              >
                {index + 1}. {category.label}
              </ToggleGroupItem>
            </div>
          </>
        ))}
      </ToggleGroup>
    </div>
  );
};
