import { Button, Container } from "@mui/material";
import styled from "@emotion/styled";

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: "#4F46E5", // Indigo-600
  color: "white",
  padding: "8px 16px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  "&:hover": {
    background: "#4338CA", // Indigo-700
    transform: "translateY(-2px)",
    boxShadow: `0 4px 12px ${theme.palette.custom.glow}`,
  },
}));

export const StyledOutlinedButton = styled(Button)(({ theme }) => ({
  color: "white",
  border: "1px solid #4F46E5",
  padding: "8px 16px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  "&:hover": {
    background: "#4338CA",
    transform: "translateY(-2px)",
    border: "1px solid #4F46E5",
    boxShadow: `0 4px 12px ${theme.palette.custom.glow}`,
  },
})); 