import {
  BlockTypeSelect,
  CreateLink,
  InsertImage,
  InsertTable,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  Separator,
  tablePlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { headingsPlugin } from "@mdxeditor/editor";
import { toolbarPlugin } from "@mdxeditor/editor";
import { UndoRedo } from "@mdxeditor/editor";
import { BoldItalicUnderlineToggles } from "@mdxeditor/editor";
import { HeadingButton } from "@mdxeditor/editor";
import { useRef, useState, useEffect } from "react";
import { Input } from "../ui/input.tsx";
import { Preview } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import api from "../../config/axios";
import { supabase } from "../../supabaseClient";

function Editor() {
  const mdxRef = useRef(null);
  const [editorContent, setEditorContent] = useState("# Start writing your blog here...");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);
  const { session } = useAuth();

  // Add error boundary state
  const [editorError, setEditorError] = useState(false);

  const checkAdminStatus = async () => {
    try {
      const response = await api.get('/check-admin');
      return response.data.is_admin;
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  };

  useEffect(() => {
    const initializeAdmin = async () => {
      if (!session) {
        setIsAdmin(false);
        return;
      }
      const adminStatus = await checkAdminStatus();
      setIsAdmin(adminStatus);
    };

    initializeAdmin();
  }, [session]);

  useEffect(() => {
    // Reset editor error state when component mounts
    setEditorError(false);
  }, []);

  const cleanContent = (content) => {
    // Remove citations without adding escape characters
    return content
      .trim();
  };

  const handleSubmit = async () => {
    try {
      const adminStatus = await checkAdminStatus();
      if (!adminStatus) {
        alert("You don't have permission to post blogs");
        return;
      }

      if (!title || !createdBy || !image) {
        alert("Please fill all required fields");
        return;
      }

      const mdx = mdxRef.current.getMarkdown();
      if (!mdx || mdx === '#Hello world') {
        alert("Please add some content to your blog");
        return;
      }

      const cleanedContent = cleanContent(mdx);

      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', cleanedContent);
      formData.append('image', image);
      formData.append('created_by', createdBy);

      const response = await api.post('/blog', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        alert("Blog posted successfully!");
        setTitle("");
        setCreatedBy("");
        setImage(null);
        mdxRef.current.setMarkdown("#Hello world");
      }
    } catch (error) {
      console.error('Error submitting blog:', error);
      alert(`Failed to submit blog: ${error.message}`);
    }
  };

  if (isAdmin === null) {
    return (
      <div className="flex justify-center min-h-96 items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="flex justify-center min-h-96 items-center">
        <h2 className="text-center text-gray-300 text-2xl font-semibold">
          This page is not accessible to you
        </h2>
      </div>
    );
  }

  return (
    <form className="flex flex-col  m-10 items-center justify-center p-10 border border-gray-700 rounded-2xl ">
      <div className="flex items-center  text-gray-300 w-full">
        <input
          type="text"
          placeholder="Title"
          required
          className="w-full  border border-gray-700 outline-0 focus:outline-0 p-2 bg-gray-900 rounded-t-lg text-xl border-r-0 rounded-r-none"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <div>
          <input
            type="text"
            placeholder="Created By"
            className="w-full  border border-gray-700  rounded-l-none outline-0 focus:outline-0 p-2 bg-gray-900 rounded-t-lg text-lg"
            required
            onChange={(e) => {
              setCreatedBy(e.target.value);
            }}
          />
        </div>
      </div>
      <Input
        type="file"
        accept="image/*"
        className="w-full border border-gray-700 text-primary  p-2  rounded-none"
        required
        onChange={(e) => {
          setImage(e.target.files[0]);
        }}
      />
      {image && (
        <div className="flex flex-col items-center gap-2">
          <img
            src={URL.createObjectURL(image)}
            alt="preview"
            className="w-full"
          />
        </div>
      )}
      <div className="prose border border-gray-700 w-full max-w-[100%]  min-h-[24rem]">
        {!editorError ? (
          <MDXEditor
            ref={mdxRef}
            markdown={editorContent}
            onChange={(content) => setEditorContent(content)}
            className="rounded-none min-h-[24rem] bg-gray-900 text-gray-100"
            plugins={[
              headingsPlugin(),
              tablePlugin(),
              linkPlugin(),
              linkDialogPlugin(),
              listsPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <Separator />
                    <BoldItalicUnderlineToggles />
                    <BlockTypeSelect />
                    <Separator />
                    <CreateLink />
                    <ListsToggle />
                    <InsertTable />
                  </>
                ),
              }),
            ]}
            onError={() => setEditorError(true)}
          />
        ) : (
          <div className="min-h-[24rem] bg-gray-900 text-gray-100 p-4">
            <p className="text-red-500">
              Error loading editor. Please refresh the page and try again.
            </p>
          </div>
        )}
      </div>
      <div className="flex mt-4">
        <Button
          onClick={handleSubmit}
          type="button"
          variant="contained"
          className="mt-4 self-start"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}
export default Editor;
