import React from "react";
import { Card, CardContent, CardFooter } from "../../../ui/card.tsx";
import { ExternalLink, Calendar } from "lucide-react";
import { Badge } from "../../../ui/badge.tsx";
import { formatDistanceToNow } from "date-fns";

const GovtNewsCard = ({ news }) => {
  const { title, summary, source_url, published_at, category, source_name } = news;

  return (
    <Card className="bg-neutral-900 border-neutral-800 hover:border-neutral-700 transition-colors">
      <CardContent className="p-4">
        <div className="flex justify-between items-start gap-2 mb-3">
          <a
            href={source_url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg font-semibold text-blue-400 hover:text-blue-300 hover:underline flex items-center gap-2 group"
          >
            {title}
            <ExternalLink className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
          </a>
        </div>
        
        <p className="text-neutral-300 text-sm mb-4 whitespace-pre-line">
          {summary}
        </p>
        
        <div className="flex items-center gap-2 text-xs text-neutral-400 mb-3">
          <Calendar className="w-4 h-4" />
          <span>{formatDistanceToNow(new Date(published_at), { addSuffix: true })}</span>
        </div>

        <div className="flex flex-wrap gap-2">
          <Badge variant="outline" className="text-xs">
            {category}
          </Badge>
          <Badge variant="secondary" className="text-xs">
            {source_name}
          </Badge>
        </div>
      </CardContent>
    </Card>
  );
};

export default GovtNewsCard; 