import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row({ interaction }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{interaction.user_query}</TableCell>
        <TableCell>
          {interaction.generated_answer.split(' ').length} words
        </TableCell>
        <TableCell>
          {new Date(interaction.created_at).toLocaleDateString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Answer
              </Typography>
              <Typography>
                {interaction.generated_answer}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function InteractionsTable({ interactions }) {
  return (
    <TableContainer component={Paper} className="bg-gray-800">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>Query</TableCell>
            <TableCell>Answer Length</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {interactions.map((interaction) => (
            <Row key={interaction.id} interaction={interaction} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InteractionsTable; 