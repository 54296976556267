import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../context/AuthContext";
import api from "../config/axios";

const ChatHistory = () => {
  const [chatSessions, setChatSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { session } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        setLoading(true);
        const { data } = await api.get("/chat/history", {
          headers: {
            authorization: `bearer ${session.access_token}`,
          },
        });

        console.log("Chat history data:", data);

        const formattedSessions = data.map((session) => ({
          id: session.id,
          title: session.title || "Untitled Chat",
          created_at: session.created_at,
          updated_at: session.updated_at,
          message_count: session.message_count || 0,
        }));

        setChatSessions(formattedSessions);
      } catch (error) {
        console.error("Error fetching chat history:", error);
      } finally {
        setLoading(false);
      }
    };

    if (session?.access_token) {
      fetchChatHistory();
    } else {
      navigate("/login");
    }
  }, [session]);

  const handleSessionClick = (sessionId) => {
    navigate(`/chat/${sessionId}`);
  };

  const handleNewChat = () => {
    navigate("/chat");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 h-[90%]">
      <div className="flex justify-between items-center mb-4">
        <Typography variant="h5">Chat History</Typography>
        <Button variant="contained" color="primary" onClick={handleNewChat}>
          New Chat
        </Button>
      </div>

      {chatSessions.length === 0 ? (
        <Paper className="p-4">
          <Typography variant="body1" className="text-center">
            No chat history yet. Start a new chat!
          </Typography>
        </Paper>
      ) : (
        <Paper className="h-full">
          <List className="h-full overflow-scroll">
            {chatSessions.map((chat) => (
              <ListItem
                button
                key={chat.id}
                onClick={() => handleSessionClick(chat.id)}
                className="hover:bg-gray-100 border-b"
              >
                <ListItemText
                  primary={
                    <div className="font-medium text-lg">
                      {chat.title || "New Chat"}
                    </div>
                  }
                  secondary={
                    <div className="flex flex-col text-sm text-gray-600">
                      <span>Created: {formatDate(chat.created_at)}</span>
                      <span>Messages: {chat.message_count}</span>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

export default ChatHistory;
