import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Link,
  IconButton,
} from "@mui/material";
import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";

const FooterContainer = styled(Box)({
  background: 'linear-gradient(to bottom, #1a1a1a, #2d2d2d)',
  color: 'white',
  padding: '4rem 0',
  borderTop: '1px solid rgba(99, 102, 241, 0.2)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: 'linear-gradient(90deg, transparent, rgba(99, 102, 241, 0.2), transparent)',
  },
});

const StyledIconButton = styled(IconButton)({
  background: 'rgba(99, 102, 241, 0.1)',
  transition: 'all 0.3s ease',
  margin: '0.5rem',
  '&:hover': {
    background: 'rgba(99, 102, 241, 0.2)',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(99, 102, 241, 0.2)',
  },
});

const StyledLink = styled(Link)({
  color: 'rgba(255, 255, 255, 0.8)',
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  display: 'block',
  marginBottom: '0.5rem',
  '&:hover': {
    color: '#6366F1',
    transform: 'translateX(4px)',
  }
});

const NewsletterForm = styled('form')({
  marginTop: '2rem',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
});

const Footer = () => {
  return (
    <FooterContainer component="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 700,
                  background: 'linear-gradient(45deg, #6366F1, #4F46E5)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginBottom: 2
                }}
              >
                compoundingAI
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.7)',
                  marginBottom: 3
                }}
              >
                From ideas to buying stock within minutes powered by our AI search
                engine and insights dashboard.
              </Typography>
              <Box>
                {/* <StyledIconButton color="inherit" aria-label="Instagram">
                  <InstagramIcon />
                </StyledIconButton> */}
                <StyledIconButton 
                  color="inherit" 
                  aria-label="Twitter"
                  component="a"
                  href="https://x.com/compoundingaiin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon />
                </StyledIconButton>
              </Box>
            </motion.div>
          </Grid>

          {/* Solutions */}
          {/* <Grid item xs={12} md={4}>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 700,
                  color: '#6366F1',
                  marginBottom: 2
                }}
              >
                Solutions
              </Typography>
              <Box>
                <StyledLink href="#">Search</StyledLink>
                <StyledLink href="#">Deep-Dive</StyledLink>
                <StyledLink href="#">FinAI Agent</StyledLink>
              </Box>
            </motion.div>
          </Grid> */}

          {/* Features Section */}
          <Grid item xs={12} md={3}>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 700,
                  color: '#6366F1',
                  marginBottom: 2
                }}
              >
                Features
              </Typography>
              <Box>
                <StyledLink href="/#concall-summaries">Concall Summaries</StyledLink>
                <StyledLink href="/#chat-announcements">Chat with Announcements</StyledLink>
                <StyledLink href="/#investor-presentation">Investor Presentation Chat</StyledLink>
                <StyledLink href="/#announcement-summaries">Announcement Summaries</StyledLink>
                <StyledLink href="/#deep-dive">Deep Dive Reports</StyledLink>
                <StyledLink href="/blogs">Blogs</StyledLink>
              </Box>
            </motion.div>
          </Grid>

          {/* Legal Links */}
          <Grid item xs={12} md={3}>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 700,
                  color: '#6366F1',
                  marginBottom: 2
                }}
              >
                Legal
              </Typography>
              <Box>
                <StyledLink href="/terms">Terms & Conditions</StyledLink>
                <StyledLink href="/privacy">Privacy Policy</StyledLink>
              </Box>
            </motion.div>
          </Grid>

          {/* Contact */}
          <Grid item xs={12} md={3}>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 700,
                  color: '#6366F1',
                  marginBottom: 2
                }}
              >
                Contact
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 1 }}
              >
                Email: support@compoundingai.in
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 1 }}
              >
                Phone: 8200860726
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box mt={5}>
          <Typography 
            variant="body2" 
            align="center"
            sx={{ color: 'rgba(255, 255, 255, 0.5)' }}
          >
            {"Copyright © "}
            <Link 
              href="https://compoundingai.in/"
              sx={{ 
                color: '#6366F1',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              CompoundingAI
            </Link>{" "}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;