import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function MetricsCard({ title, value }) {
  return (
    <Card className="bg-gray-800">
      <CardContent>
        <Typography variant="h6" component="div" className="text-gray-400">
          {title}
        </Typography>
        <Typography variant="h4" component="div" className="mt-2">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default MetricsCard; 