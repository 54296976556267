import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
});

const StyledCardMedia = styled(CardMedia)({
  paddingTop: "56.25%", // 16:9 aspect ratio
  backgroundSize: "contain",
  backgroundColor: "#f5f5f5",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledTypography = styled(Typography)({
  color: "#000000",
});

const BlackDivider = styled(Divider)({
  backgroundColor: "rgba(0, 0, 0, 0.12)",
});

const ButtonContainer = styled(Box)({
  marginTop: "auto",
  padding: "16px",
});

const PriceButton = styled(Button)(({ theme, selected }) => ({
  margin: "4px",
  minWidth: "120px",
  backgroundColor: selected ? theme.palette.primary.main : "transparent",
  color: selected ? "#fff" : theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    backgroundColor: selected
      ? theme.palette.primary.dark
      : "rgba(25, 118, 210, 0.04)",
  },
}));

const CardItem = ({
  icon,
  title,
  monthlyPrice,
  yearlyPrice,
  features,
  comingSoonFeatures,
  buttonLabel,
  plan,
  paid,
}) => {
  const { session } = useAuth();
  const [priceTab, setPriceTab] = useState(1); // 0 for monthly, 1 for yearly (default yearly)
  const navigate = useNavigate();
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "http://localhost:5000";

  async function handlePayment(e, plan) {
    e.preventDefault();
    if (session == null || paid === false) {
      navigate("/login");
      return;
      // window.location.href = "/login";
    } else {
      e.preventDefault();
      let paymentMode = priceTab === 0 ? "monthly" : "yearly";
      try {
        const res = await fetch(`${API_URL}/generateOrder`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.accessToken}`,
          },
          body: JSON.stringify({ plan: plan, paymentMode: paymentMode }),
        });

        const order = await res.json();
        let options = {
          key: process.env.REACT_APP_RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
          amount: order.amount,
          currency: "INR",
          name: "Compounding AI",
          description: `Payment for ${plan} plan`,
          image: "/new_logo_v1.png",
          order_id: "order_PXoNbOXXcS9hFo", //This is a sample Order ID. Pass the `id` obtained in the response of Step 3
          callback_url: `${API_URL}/verifyPayment`,
          prefill: {
            name: "Gaurav Kumar",
            email: "gaurav.kumar@example.com",
            contact: "9000090000",
          },
          notes: {},
          theme: {
            color: "#3399cc",
          },
        };
        let rzp1 = new window.Razorpay(options);
        rzp1.open();
        e.preventDefault();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <StyledCard>
      <StyledCardMedia image={icon} title={title} />
      <StyledCardContent>
        <StyledTypography
          gutterBottom
          variant="h5"
          component="h2"
          align="center"
          fontWeight="bold"
          style={{ color: "#1976d2" }}
        >
          {title}
        </StyledTypography>

        {/* Price Selection Buttons */}
        {monthlyPrice && yearlyPrice && (
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 1, mb: 2 }}
          >
            <PriceButton
              selected={priceTab === 0}
              onClick={() => setPriceTab(0)}
              size="small"
            >
              Monthly
            </PriceButton>
            <PriceButton
              selected={priceTab === 1}
              onClick={() => setPriceTab(1)}
              size="small"
            >
              Yearly
            </PriceButton>
          </Box>
        )}

        <Box sx={{ my: 2 }}>
          {/* Price Display */}
          {monthlyPrice && yearlyPrice && (
            <StyledTypography
              variant="h4"
              component="p"
              align="center"
              fontWeight="bold"
              style={{ color: "#f50057" }}
            >
              ₹{priceTab === 0 ? monthlyPrice : yearlyPrice}
              <Typography
                variant="caption"
                component="span"
                sx={{ display: "block", color: "text.secondary" }}
              >
                {priceTab === 0 ? "/month" : "/year"}
              </Typography>
            </StyledTypography>
          )}
          {/* Free and Enterprise Plans */}
          {!monthlyPrice && !yearlyPrice && (
            <StyledTypography
              variant="h4"
              component="p"
              align="center"
              fontWeight="bold"
              style={{ color: "#f50057" }}
            >
              {title === "Free Plan" ? "" : "Contact Us"}
            </StyledTypography>
          )}
        </Box>

        <BlackDivider sx={{ my: 2 }} />

        {/* Features List */}
        <Box sx={{ flexGrow: 1 }}>
          {features &&
            features.map((feature, index) => (
              <React.Fragment key={index}>
                <StyledTypography
                  variant="body1"
                  align="center"
                  paragraph
                  style={{ color: "#000000", fontWeight: 500 }}
                >
                  {feature}
                </StyledTypography>
                {index < features.length - 1 && <BlackDivider sx={{ my: 1 }} />}
              </React.Fragment>
            ))}
        </Box>

        {/* Coming Soon Features */}
        {comingSoonFeatures && (
          <Box sx={{ mt: 2, mb: 3 }}>
            <StyledTypography
              variant="body2"
              align="center"
              fontWeight="bold"
              style={{ color: "#757575" }}
            >
              Coming Soon
            </StyledTypography>
            {comingSoonFeatures.map((feature, index) => (
              <StyledTypography
                key={index}
                variant="body2"
                align="center"
                paragraph
                style={{ color: "#757575", fontStyle: "italic" }}
              >
                {feature}
              </StyledTypography>
            ))}
          </Box>
        )}
      </StyledCardContent>

      {/* Button */}
      <Button
        component={RouterLink}
        // to={title === "Free Plan" ? "/register" : "/contact"}
        variant="contained"
        color="primary"
        size="large"
        sx={{ m: 2 }}
        onClick={(e) => handlePayment(e, plan)}
      >
        {buttonLabel}
      </Button>
    </StyledCard>
  );
};

export default CardItem;
