import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../config/axios";
import ReactMarkdown from "react-markdown";
import { useQuery } from "@tanstack/react-query";
import rehypeRaw from "rehype-raw";
import { Rating } from "@mui/material";
import { categories, DeepDiveSelect } from "./DeepDiveSelect";
import { Separator } from "../ui/separator.tsx";
import { Skeleton } from "../ui/skeleton.tsx";
import SearchInput from "../Search/SearchInput.jsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../ui/dropdown.tsx";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { ArrowDown, ChevronDown } from "lucide-react";
import { supabase } from "../../supabaseClient.js";
import { DeepDiveSidebar } from "./DeepDiveSidebar";
import { sections } from "./constants";
import { ConcallContent } from "./ConcallContent";
import { Spinner } from "../ui/spinner.tsx";
import { fetchReportStatus } from "./api/reportApi";
import DeepDivePDF from './DeepDivePDF';


const fetchReport = async (ticker, category) => {
  try {
    const response = await api.post("/report", {
      ticker: ticker,
      category: category,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching report:", error);
    throw error;
  }
};
const fetchCompany = async (ticker) => {
  try {
    const { data: company } = await supabase
      .from("companies")
      .select("*")
      .eq("Security Id", ticker)
      .single();
    return company;
  } catch (error) {
    console.error("Error fetching company:", error);
    throw error;
  }
};
const ratingColors = (rating) => {
  if (rating === "Poor") {
    return "text-red-500";
  } else if (rating === "Below Average") {
    return "text-orange-500"; // orange
  } else if (rating === "Average") {
    return "text-yellow-500"; // yellow
  } else if (rating === "Strong") {
    return "text-green-500"; // green
  } else if (rating === "Exceptional") {
    return "text-blue-500"; // blue
  }
};

const DeepDiveContent = ({ data, isLoading, category, ticker }) => {
  const { data: companyData } = useQuery({
    queryKey: ["company", ticker],
    queryFn: () => fetchCompany(ticker),
    staleTime: 1000 * 60 * 60,
  });

  const getCategoryLabel = () => {
    const categoryObj = categories.find(cat => cat.value === category);
    return categoryObj ? categoryObj.label : '';
  };

  return (
    <div className="h-full overflow-y-auto px-6 py-4">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-medium text-neutral-200">
          {getCategoryLabel()}
        </h1>
        {data && companyData && (
          <DeepDivePDF 
            content={data} 
            title={getCategoryLabel()} 
            category={category}
            ticker={ticker}
            companyName={companyData["Security Name"]}
          />
        )}
      </div>

      {data && data.rating && (
        <div className="mb-4">
          <span className={`font-medium text-base ${data && ratingColors(data.rating)}`}>
            Rating: {data && data.rating}
          </span>
        </div>
      )}
      <div className="text-neutral-200 prose prose-invert max-w-none">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => (
              <p className="mb-4 text-sm leading-relaxed" {...props} />
            ),
            strong: ({ node, ...props }) => (
              <strong className="font-medium" {...props} />
            ),
            ul: ({ node, ...props }) => (
              <ul className="list-disc ml-4 mb-4 text-sm" {...props} />
            ),
            ol: ({ node, ...props }) => (
              <ol className="list-decimal ml-4 mb-4 text-sm" {...props} />
            ),
            li: ({ node, ...props }) => (
              <li className="mb-2 text-sm" {...props} />
            ),
            h1: ({ node, ...props }) => (
              <h1 className="text-xl font-medium mb-4" {...props} />
            ),
            h2: ({ node, ...props }) => (
              <h2 className="text-lg font-medium mb-3" {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 className="text-base font-medium mb-3" {...props} />
            ),
            blockquote: ({ node, ...props }) => (
              <blockquote className="border-l-2 border-neutral-700 pl-4 italic my-4" {...props} />
            ),
            a: ({ node, ...props }) => (
              <a className="text-blue-500 hover:underline" {...props} />
            ),
          }}
        >
          {data.text}
        </ReactMarkdown>
      </div>
    </div>
  );
};
const DeepDiveHeading = ({ ticker }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["company", ticker],
    queryFn: () => fetchCompany(ticker),
    staleTime: 1000 * 60 * 60,
  });
  if (isLoading) {
    return <Skeleton className="w-36 h-10" />;
  }
  if (isError) {
    return (
      <h1 className="text-2xl font-terminal line-clamp-1">Company Not Found</h1>
    );
  }
  if (data) {
    return (
      <h1 className="text-2xl font-mono  line-clamp-1">
        {data && data["Security Name"]}
      </h1>
    );
  }
};

const DeepDive = () => {
  const [category, setCategory] = useState(sections.concall.value);
  const { ticker } = useParams();
  const [isGenerating, setIsGenerating] = useState(false);
  
  const { data, isLoading, isError, isFetching } = useQuery({
    queryKey: ["deep-dive", ticker, category],
    queryFn: () => fetchReport(ticker, category),
    staleTime: 1000 * 60 * 60,
    enabled: category !== 'concall',
    retry: (failureCount, error) => {
      if (error.message === 'REPORT_GENERATING') {
        setIsGenerating(true);
        return true;
      }
      return failureCount < 3;
    },
    retryDelay: 5000,
    onSuccess: () => setIsGenerating(false)
  });

  // Add status polling when report is generating
  useQuery({
    queryKey: ["report-status", ticker, category],
    queryFn: () => fetchReportStatus(ticker, category),
    enabled: isGenerating,
    refetchInterval: 3000,
    onSuccess: (data) => {
      if (data.status === 'completed') {
        setIsGenerating(false);
      }
    }
  });

  const handleCategoryChange = (category) => {
    setCategory(category);
  };

  const renderContent = () => {
    if (isLoading || isFetching) {
      return (
        <div className="p-8 space-y-4">
          <Skeleton className="w-full h-24" />
          <div className="flex items-center gap-2 text-neutral-400">
            <Spinner className="w-4 h-4 animate-spin" />
            <span>Generating report... This might take a minute for first-time analysis</span>
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="p-4">
          <h1 className="text-xl font-mono text-start mt-6">
            Data is not available for this company now, Check back later
          </h1>
        </div>
      );
    }

    return <DeepDiveContent data={data} category={category} ticker={ticker} />;
  };

  return (
    <div className="flex flex-col h-screen">
      <SearchInput />
      <div className="border-b border-neutral-700 p-4">
        <DeepDiveHeading ticker={ticker} />
      </div>
      <div className="flex flex-1 min-h-0">
        <DeepDiveSidebar selected={category} onSelect={handleCategoryChange} />
        <div className="flex-1 overflow-hidden">
          {category === 'concall' ? (
            <ConcallContent />
          ) : (
            renderContent()
          )}
        </div>
      </div>
    </div>
  );
};
export default DeepDive;
