import React, { useState, useEffect } from "react";
import {
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../ui/dropdown.tsx";
import { SlidersHorizontal } from "lucide-react";
import { Checkbox } from "../ui/checkbox.tsx";
import { Switch } from "../ui/switch.tsx";
import { supabase } from "../../supabaseClient.js";

export const quarterFilters = [
  { label: "Q1", value: "Q1" },
  { label: "Q2", value: "Q2" },
  { label: "Q3", value: "Q3" },
  { label: "Q4", value: "Q4" },
];
export const sourceFilters = [
  { label: "Earning Transcript", value: "Earning Transcript" },
  { label: "Annual Report", value: "Annual Report" },
  { label: "Investor Presentation", value: "Investor Presentation" },
];
export const yearFilters = [];
for (let i = new Date(Date.now()).getFullYear(); i > 2009; i--) {
  yearFilters.push({ label: i.toString(), value: i.toString() });
}
export default function ChatFilter() {
  const [quarterFilter, setQuarterFilter] = useState([]);
  const [sourceFilter, setSourceFilter] = useState([]);
  const [yearFilter, setYearFilter] = useState([]);
  const [allFiltersSelected, setAllFiltersSelected] = useState(true);
  const showFiltersOnAllFilters = () => {
    if (allFiltersSelected) {
      setYearFilter(yearFilters.map((filter) => filter.value));
      setQuarterFilter(quarterFilters.map((filter) => filter.value));
      setSourceFilter(sourceFilters.map((filter) => filter.value));
    } else {
      setYearFilter([]);
      setQuarterFilter([]);
      setSourceFilter([]);
    }
  };
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) {
          throw error;
        }
        if (data?.user?.user_metadata?.filters) {
          const savedFilters = data.user.user_metadata.filters;

          setYearFilter(savedFilters.year_filter || []);
          setQuarterFilter(savedFilters.quarter_filter || []);
          setSourceFilter(savedFilters.source_filter || []);
          setAllFiltersSelected(savedFilters.all_filters_selected);
        } else {
          // Initialize defaults if no filters are saved
          setYearFilter([]);
          setQuarterFilter([]);
          setSourceFilter([]);
          setAllFiltersSelected(true);
          await saveFiltersToSession({
            yearFilter: [],
            quarterFilter: [],
            sourceFilter: [],
            allFiltersSelected: true,
          });
        }
      } catch (error) {
        console.error("Error fetching filters from session:", error);
      }
    };

    fetchFilters();
  }, []);

  const handleAllFiltersToggle = async (e) => {
    const newAllFiltersSelected = !allFiltersSelected;
    setAllFiltersSelected(newAllFiltersSelected);
    await saveFiltersToSession({
      yearFilter,
      quarterFilter,
      sourceFilter,
      allFiltersSelected: newAllFiltersSelected,
    });
  };
  const handleSourceToggle = async (e, value) => {
    e.preventDefault();
    const newSourceFilter = sourceFilter.includes(value)
      ? sourceFilter.filter((item) => item !== value)
      : [...sourceFilter, value];
    setSourceFilter(newSourceFilter);
    setAllFiltersSelected(false);
    saveFiltersToSession({
      yearFilter,
      quarterFilter,
      sourceFilter: newSourceFilter,
      allFiltersSelected,
    });
  };
  const handleQuarterToggle = async (e, value) => {
    e.preventDefault();
    const newQuarterFilter = quarterFilter.includes(value)
      ? quarterFilter.filter((item) => item !== value)
      : [...quarterFilter, value];
    setQuarterFilter(newQuarterFilter);
    setAllFiltersSelected(false);
    saveFiltersToSession({
      yearFilter,
      quarterFilter: newQuarterFilter,
      sourceFilter,
      allFiltersSelected,
    });
  };
  const handleYearToggle = async (e, value) => {
    e.preventDefault();
    const newYearFilter = yearFilter.includes(value)
      ? yearFilter.filter((item) => item !== value)
      : [...yearFilter, value];
    setYearFilter(newYearFilter);
    setAllFiltersSelected(false);
    await saveFiltersToSession({
      yearFilter: newYearFilter,
      quarterFilter,
      sourceFilter,
      allFiltersSelected,
    });
  };

  const saveFiltersToSession = async (filters) => {
    try {
      const { data, error } = await supabase.auth.updateUser({
        data: {
          filters: {
            year_filter: filters.yearFilter,
            quarter_filter: filters.quarterFilter,
            source_filter: filters.sourceFilter,
            all_filters_selected: filters.allFiltersSelected,
          },
        }, // Add filters to user_metadata
      });
      console.log(
        "Filters updated in session:",
        data.user.user_metadata.filters,
      );

      if (error) {
        throw error;
      }
      console.log("Filters updated in session:", data);
    } catch (error) {
      console.error("Error updating filters in session:", error);
    }
  };

  console.log("All Filters: ", allFiltersSelected);
  const selectFilter = (e) => {
    e.preventDefault();
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="p-2 hover:bg-neutral-700 rounded-lg">
        <SlidersHorizontal
          className={`${yearFilter.length + sourceFilter.length + quarterFilter.length > 0 ? "text-blue-500" : ""}`}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-neutral-800 border-neutral-700">
        <DropdownMenuLabel>
          <div className="flex items-center justify-between">
            Filters
            <Switch
              className="bg-neutral-900 selection:bg-neutral-900  data-[state=unchecked]:bg-neutral-600"
              checked={allFiltersSelected}
              onCheckedChange={handleAllFiltersToggle}
            />
          </div>
        </DropdownMenuLabel>

        <DropdownMenuSeparator className="border-neutral-600 bg-neutral-700" />
        <DropdownMenuSub>
          <DropdownMenuSubTrigger
            disabled={allFiltersSelected}
            className={`${allFiltersSelected ? "text-neutral-500" : ""}`}
          >
            Source
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="bg-neutral-800 border-neutral-700">
            {sourceFilters.map((filter) => (
              <DropdownMenuItem
                key={filter.value}
                value={filter.value}
                onClick={(e) => handleSourceToggle(e, filter.value)}
                className="cursor-pointer"
              >
                <div className="flex items-center">
                  <Checkbox
                    className=""
                    checked={sourceFilter.includes(filter.value)}
                    onChange={(e) => handleSourceToggle(e, filter.value)}
                  />
                  <span className="ml-2">{filter.label}</span>
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <DropdownMenuSeparator className="border-neutral-600 bg-neutral-700" />
        <DropdownMenuSub>
          <DropdownMenuSubTrigger
            disabled={allFiltersSelected}
            className={`${allFiltersSelected ? "text-neutral-500" : ""}`}
          >
            Quarters
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent
            onClick={selectFilter}
            className="bg-neutral-800 border-neutral-700"
          >
            {quarterFilters.map((filter) => (
              <DropdownMenuItem
                key={filter.value}
                value={filter.value}
                onClick={(e) => handleQuarterToggle(e, filter.value)}
                className="cursor-pointer"
              >
                <div className="flex items-center">
                  <Checkbox
                    className=""
                    checked={quarterFilter.includes(filter.value)}
                    onChange={(e) => handleQuarterToggle(e, filter.value)}
                  />
                  <span className="ml-2">{filter.label}</span>
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <DropdownMenuSeparator className="border-neutral-600 bg-neutral-700" />
        <DropdownMenuSub>
          <DropdownMenuSubTrigger
            disabled={allFiltersSelected}
            className={`${allFiltersSelected ? "text-neutral-500" : ""}`}
          >
            Year
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="bg-neutral-800 border-neutral-700 max-h-40 overflow-y-scroll">
            {yearFilters.map((filter) => (
              <DropdownMenuItem
                key={filter.value}
                value={filter.value}
                onClick={(e) => handleYearToggle(e, filter.value)}
                className="cursor-pointer"
              >
                <div className="flex items-center">
                  <Checkbox
                    className=""
                    checked={yearFilter.includes(filter.value)}
                    onChange={(e) => handleYearToggle(e, filter.value)}
                  />
                  <span className="ml-2">{filter.label}</span>
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuSubContent>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
