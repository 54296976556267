import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "rgba(10, 10, 10, 0.8)",
  backdropFilter: "blur(12px)",
  borderBottom: `1px solid ${theme.palette.custom.border}`,
  height: "70px",
  display: "flex",
  justifyContent: "center",
  zIndex: 1000,
  boxShadow: `0 4px 30px ${theme.palette.custom.glow}`,
}));

const StyledToolbar = styled(Toolbar)({
  minHeight: "70px !important",
  width: "100%",
});

const StyledButton = styled(Button)({
  color: "#fff",
  padding: "8px 16px",
  borderRadius: "8px",
  transition: "all 0.3s ease",
  position: "relative",
  overflow: "hidden",
  fontWeight: 500,
  "&:hover": {
    background: "rgba(99, 102, 241, 0.1)",
    transform: "translateY(-2px)",
    color: "#6366F1",
    "&::after": {
      transform: "scaleX(1)",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "2px",
    background: "linear-gradient(90deg, #6366F1, #4F46E5)",
    transform: "scaleX(0)",
    transformOrigin: "right",
    transition: "transform 0.3s ease",
  },
});

export const LogoText = styled(Typography)({
  background: "linear-gradient(45deg, #40E0D0, #4F46E5)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 700,
  letterSpacing: ".1rem",
  textDecoration: "none",
  fontSize: "1.5rem",
  whiteSpace: "nowrap",
  padding: "0.5rem 0",
});

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    background: "linear-gradient(to bottom, #1a1a1a, #2d2d2d)",
    border: "1px solid rgba(99, 102, 241, 0.2)",
    borderRadius: "12px",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.4)",
  },
  "& .MuiMenuItem-root": {
    color: "#fff",
    transition: "all 0.2s ease",
    "&:hover": {
      background: "rgba(99, 102, 241, 0.1)",
      color: "#6366F1",
    },
  },
});

const CTAButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #6366F1, #4F46E5)",
  color: "white",
  padding: "8px 24px",
  borderRadius: "8px",
  marginLeft: "16px",
  fontWeight: 600,
  transition: "all 0.3s ease",
  "&:hover": {
    background: "linear-gradient(45deg, #4F46E5, #4338CA)",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(99, 102, 241, 0.3)",
  },
}));

const pages = [
  { name: "Home", path: "home", isScroll: true },
  { name: "Features", path: "features", isScroll: true },
  { name: "Blogs", path: "/blogs", isScroll: false }
];

function Navbar() {
  const { session } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const NavItem = ({ to, children, isScroll }) => {
    const handleClick = () => {
      handleCloseNavMenu();
      
      // If not on homepage, redirect to homepage first
      if (!isHomePage && isScroll) {
        window.location.href = `/#${to}`;
      }
    };

    const content = (
      <motion.div 
        whileHover={{ scale: 1.05 }} 
        whileTap={{ scale: 0.95 }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <StyledButton>
          <Typography 
            sx={{ 
              fontSize: '1rem',
              fontWeight: 500,
              textTransform: 'none'
            }}
          >
            {children}
          </Typography>
        </StyledButton>
      </motion.div>
    );

    return isScroll && isHomePage ? (
      <ScrollLink
        to={to}
        smooth={true}
        duration={500}
        offset={-70} // Adjust this value based on your navbar height
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={handleClick}
      >
        {content}
      </ScrollLink>
    ) : (
      <Link 
        to={isScroll ? `/#${to}` : to} 
        style={{ textDecoration: "none" }} 
        onClick={handleClick}
      >
        {content}
      </Link>
    );
  };

  return (
    <StyledAppBar position="sticky">
      <Container maxWidth="xl">
        <StyledToolbar disableGutters>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <LogoText
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
              }}
            >
              compoundingAI
            </LogoText>
          </motion.div>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              sx={{
                color: "rgba(255, 255, 255, 0.8)",
                "&:hover": {
                  background: "rgba(99, 102, 241, 0.1)",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/**{pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))} */}
            </StyledMenu>
          </Box>

          <LogoText
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontSize: "1.2rem",
            }}
          >
            compoundingAI
          </LogoText>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              gap: 2,
              alignItems: "center",
            }}
          >
            {pages.map((page) => (
              <NavItem
                key={page.name}
                to={page.path}
                isScroll={page.isScroll}
              >
                {page.name}
              </NavItem>
            ))}
            <CTAButton 
              component={Link} 
              to="/contact-sales"
              sx={{
                fontSize: '1rem',
                textTransform: 'none',
              }}
            >
              Get Started
            </CTAButton>
          </Box>
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  );
}

export default Navbar;
