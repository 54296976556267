import React, { useState, useMemo, useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "../../../../supabaseClient";
import { Input } from "../../../ui/input.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../ui/dropdown.tsx";
import { Button } from "../../../ui/button.tsx";
import { ChevronDown, Search, Calendar as CalendarIcon, ArrowUpDown } from "lucide-react";
import { Badge } from "../../../ui/badge.tsx";
import { format, formatDistanceToNow, addMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-custom.css";

const sentimentColors = {
  positive: "bg-green-500/20 text-green-500",
  negative: "bg-red-500/20 text-red-500",
  neutral: "bg-blue-500/20 text-blue-500",
};

const sortOptions = [
  { label: "Relevance", value: "relevance" },
  { label: "Latest First", value: "latest" },
  { label: "Oldest First", value: "oldest" },
];

const parseISTDate = (dateString) => {
  // Parse the ISO string and subtract 330 minutes to compensate for browser's UTC conversion
  return addMinutes(new Date(dateString), -330);
};

const fetchAnnouncements = async ({ filters, sortBy, page, pageSize }) => {
  let query = supabase
    .from('company_announcements')
    .select('*', { count: 'exact' });

  // Apply ticker filter
  if (filters.ticker) {
    query = query.ilike('ticker', `%${filters.ticker}%`);
  }

  // Apply category filter
  if (filters.category) {
    query = query.eq('category', filters.category);
  }

  // Apply sentiment filter
  if (filters.sentiment) {
    query = query.eq('sentiment', filters.sentiment);
  }

  // Apply date range filters
  if (filters.startDate) {
    const start = new Date(filters.startDate);
    start.setHours(0, 0, 0, 0);
    query = query.gte('created_at', addMinutes(start, 330).toISOString());
  }
  if (filters.endDate) {
    const end = new Date(filters.endDate);
    end.setHours(23, 59, 59, 999);
    query = query.lte('created_at', addMinutes(end, 330).toISOString());
  }

  // Apply sorting
  if (sortBy === 'latest') {
    query = query.order('created_at', { ascending: false });
  } else if (sortBy === 'oldest') {
    query = query.order('created_at', { ascending: true });
  } else {
    query = query
      .order('importance_score', { ascending: false })
      .order('created_at', { ascending: false });
  }

  // Add pagination
  const from = (page - 1) * pageSize;
  query = query.range(from, from + pageSize - 1);

  const { data, error, count } = await query;
  if (error) throw error;
  
  return { data, totalCount: count };
};

const AnnouncementsTable = () => {
  const [filters, setFilters] = useState({
    ticker: "",
    category: "",
    sentiment: "",
    summarySearch: "",
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    ticker: "",
    category: "",
    sentiment: "",
    summarySearch: "",
    startDate: null,
    endDate: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [sortBy, setSortBy] = useState("relevance");
  const [tickerSuggestions, setTickerSuggestions] = useState([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const tickerInputRef = useRef(null);

  const { data: announcements, isLoading, isError } = useQuery({
    queryKey: ['company-announcements', appliedFilters, sortBy, currentPage, pageSize],
    queryFn: async () => {
      const result = await fetchAnnouncements({ 
        filters: appliedFilters, 
        sortBy,
        page: currentPage,
        pageSize 
      });

      // Apply client-side summary search if needed
      if (appliedFilters.summarySearch) {
        result.data = result.data.filter(announcement => 
          announcement.summary.toLowerCase().includes(appliedFilters.summarySearch.toLowerCase())
        );
        result.totalCount = result.data.length;
      }
      return result;
    },
    staleTime: 5 * 60 * 1000,
  });

  const filterOptions = useMemo(() => {
    if (!announcements) return {};
    return {
      category: [...new Set(announcements.data.map(a => a.category))],
      sentiment: [...new Set(announcements.data.map(a => a.sentiment))],
    };
  }, [announcements]);

  const filteredData = useMemo(() => {
    if (!announcements) return [];
    
    return announcements.data.filter(announcement => {
      const matchesFilters = (
        (!filters.ticker || announcement.ticker.toLowerCase().includes(filters.ticker.toLowerCase())) &&
        (!filters.category || announcement.category === filters.category) &&
        (!filters.sentiment || announcement.sentiment === filters.sentiment)
      );

      const matchesSummary = !filters.summarySearch || 
        announcement.summary.toLowerCase().includes(filters.summarySearch.toLowerCase());

      return matchesFilters && matchesSummary;
    });
  }, [announcements, filters]);

  const sortedData = useMemo(() => {
    if (!filteredData) return [];
    
    const data = [...filteredData];
    
    switch (sortBy) {
      case "latest":
        return data.sort((a, b) => parseISTDate(b.created_at) - parseISTDate(a.created_at));
      case "oldest":
        return data.sort((a, b) => parseISTDate(a.created_at) - parseISTDate(b.created_at));
      default:
        // Relevance sorting
        return data.sort((a, b) => {
          if (a.priority !== b.priority) {
            return a.priority - b.priority;
          }
          if (a.importance_score !== b.importance_score) {
            return b.importance_score - a.importance_score;
          }
          return parseISTDate(b.created_at) - parseISTDate(a.created_at);
        });
    }
  }, [filteredData, sortBy]);

  // Categories and sentiments for dropdowns
  const categories = [
    "Investor Relations & Meetings",
    "Corporate Governance & Board-level Changes",
    "Regulatory & Compliance",
    "Mergers & Acquisitions",
    "Partnerships & Collaborations",
    "Operating Updates",
    "Capital Market Activities",
    "Public/Media Communication"
  ];
  const sentiments = ["Positive", "Cautionary Optimism", "Negative", "Neutral"];

  const handleApplyFilters = () => {
    setAppliedFilters({
      ...filters,
      ...dateRange,
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setFilters({
      ticker: "",
      category: "",
      sentiment: "",
      summarySearch: "",
    });
    setDateRange({
      startDate: null,
      endDate: null,
    });
    setAppliedFilters({
      ticker: "",
      category: "",
      sentiment: "",
      summarySearch: "",
      startDate: null,
      endDate: null,
    });
    setCurrentPage(1);
  };

  const renderSortOptions = () => (
    <div className="flex justify-end mb-4">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm">
            Sort by: {sortOptions.find(opt => opt.value === sortBy)?.label}
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {sortOptions.map((option) => (
            <DropdownMenuItem
              key={option.value}
              onClick={() => setSortBy(option.value)}
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );

  const fetchSuggestions = async (query) => {
    if (!query) {
      setTickerSuggestions([]);
      return;
    }
    try {
      const { data: companies } = await supabase
        .from("companies")
        .select("*")
        .or(`Security Id.ilike.%${query}%,Security Name.ilike.%${query}%`)
        .limit(10);
      setTickerSuggestions(companies || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setTickerSuggestions([]);
    }
  };

  // Debounced search effect
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSuggestions(filters.ticker);
    }, 300);

    return () => clearTimeout(timer);
  }, [filters.ticker]);

  const renderTickerSearch = () => (
    <div className="relative">
      <label className="text-sm text-neutral-400 mb-2 block">Company Search</label>
      <div className="relative">
        <Input
          ref={tickerInputRef}
          placeholder="Search by company name or ticker..."
          value={filters.ticker}
          onChange={(e) => {
            const value = e.target.value;
            setFilters(prev => ({ ...prev, ticker: value }));
            if (!value) {
              setTickerSuggestions([]);
            }
          }}
          onFocus={() => setSearchFocused(true)}
          onBlur={(e) => {
            if (!e.relatedTarget || !e.relatedTarget.closest(".search-menu")) {
              setTimeout(() => setSearchFocused(false), 200);
            }
          }}
          className="bg-neutral-900 pl-9"
        />
        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-blue-400" />
      </div>

      <div
        className={`bg-neutral-900 ${
          tickerInputRef.current && filters.ticker && searchFocused ? "absolute" : "hidden"
        } w-[100%] border border-neutral-700 rounded-md mt-1 max-h-[50svh] overflow-scroll search-menu z-10`}
      >
        {tickerSuggestions.length > 0 && (
          <ul className="bg-neutral-900 w-full">
            {tickerSuggestions.map((item) => (
              <li
                key={item["Security Id"]}
                className="bg-neutral-900 p-2 border-b border-neutral-700 hover:bg-neutral-800 cursor-pointer"
                onMouseDown={() => {
                  const ticker = item["Security Id"];
                  setFilters(prev => ({ ...prev, ticker }));
                  setAppliedFilters(prev => ({ ...prev, ticker }));
                  setTickerSuggestions([]);
                  setSearchFocused(false);
                }}
              >
                <div className="text-sm text-neutral-300">{item["Security Name"]}</div>
                <div className="font-mono text-blue-400 text-xs">{item["Security Id"]}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );

  // Filter UI section
  const renderFilterSection = () => (
    <div className="space-y-4 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {renderTickerSearch()}
        {/* Category Filter */}
        <div>
          <label className="text-sm text-neutral-400 mb-2 block">Category</label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {filters.category || "Select category"}
                <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[200px]">
              {categories.map((category) => (
                <DropdownMenuItem
                  key={category}
                  onClick={() => setFilters(prev => ({ ...prev, category }))}
                >
                  {category}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Sentiment Filter */}
        <div>
          <label className="text-sm text-neutral-400 mb-2 block">Sentiment</label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {filters.sentiment || "Select Sentiment"}
                <ChevronDown className="h-4 w-4 opacity-50" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => setFilters(prev => ({ ...prev, sentiment: "" }))}>
                All Sentiments
              </DropdownMenuItem>
              {sentiments.map(sentiment => (
                <DropdownMenuItem
                  key={sentiment}
                  onClick={() => setFilters(prev => ({ ...prev, sentiment }))}
                >
                  {sentiment}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Summary Search */}
        <div>
          <label className="text-sm text-neutral-400 mb-2 block">Search in Summary</label>
          <Input
            placeholder="Search text..."
            value={filters.summarySearch}
            onChange={(e) => setFilters(prev => ({ ...prev, summarySearch: e.target.value }))}
          />
        </div>
      </div>

      {/* Date Range Picker Row */}
      <div className="flex flex-wrap gap-4 items-end">
        <div>
          <label className="text-sm text-neutral-400 mb-2 block">Start Date</label>
          <DatePicker
            selected={dateRange.startDate}
            onChange={(date) => setDateRange(prev => ({ ...prev, startDate: date }))}
            placeholderText="Start Date"
            className="flex h-9 rounded-md border border-neutral-800 bg-transparent px-3 py-1 text-sm"
            maxDate={dateRange.endDate || new Date()}
            dateFormat="MMM d, yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        <div>
          <label className="text-sm text-neutral-400 mb-2 block">End Date</label>
          <DatePicker
            selected={dateRange.endDate}
            onChange={(date) => setDateRange(prev => ({ ...prev, endDate: date }))}
            placeholderText="End Date"
            className="flex h-9 rounded-md border border-neutral-800 bg-transparent px-3 py-1 text-sm"
            minDate={dateRange.startDate}
            maxDate={new Date()}
            dateFormat="MMM d, yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>

        <div className="flex gap-4">
          <Button 
            onClick={handleApplyFilters} 
            variant="default"
            disabled={!dateRange.startDate && !dateRange.endDate && !filters.ticker && !filters.category && !filters.sentiment && !filters.summarySearch}
          >
            Apply Filters
          </Button>
          <Button onClick={handleResetFilters} variant="outline">
            Reset
          </Button>
        </div>
      </div>
    </div>
  );

  // Render loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Render error state
  if (isError) {
    return (
      <div className="text-center p-6 text-red-500">
        Error loading announcements. Please try again later.
      </div>
    );
  }

  // Render active filters
  const renderActiveFilters = () => {
    const activeFilters = [];
    if (appliedFilters.ticker) activeFilters.push(`Ticker: ${appliedFilters.ticker}`);
    if (appliedFilters.category) activeFilters.push(`Category: ${appliedFilters.category}`);
    if (appliedFilters.sentiment) activeFilters.push(`Sentiment: ${appliedFilters.sentiment}`);
    if (appliedFilters.summarySearch) activeFilters.push(`Summary: ${appliedFilters.summarySearch}`);
    if (appliedFilters.startDate) activeFilters.push(`From: ${format(appliedFilters.startDate, 'MMM d, yyyy')}`);
    if (appliedFilters.endDate) activeFilters.push(`To: ${format(appliedFilters.endDate, 'MMM d, yyyy')}`);

    if (activeFilters.length === 0) return null;

    return (
      <div className="flex flex-wrap gap-2 mb-4">
        {activeFilters.map((filter, index) => (
          <Badge key={index} variant="secondary" className="px-2 py-1">
            {filter}
          </Badge>
        ))}
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={handleResetFilters}
          className="text-red-400 hover:text-red-300"
        >
          Clear All
        </Button>
      </div>
    );
  };

  return (
    <div className="space-y-6 p-6">
      {renderFilterSection()}
      {renderActiveFilters()}
      {renderSortOptions()}
      
      {announcements?.data.length === 0 ? (
        <div className="text-center py-12 text-neutral-400">
          No announcements found matching your filters.
        </div>
      ) : (
        <>
          <div className="space-y-6">
            {announcements?.data.map((announcement) => (
              <div key={announcement.id} className="border border-neutral-800 rounded-lg p-4">
                <div className="flex items-start justify-between gap-4 mb-3">
                  <div>
                    <div className="flex items-center gap-2 mb-2">
                      <span className="font-mono text-blue-400">{announcement.ticker}</span>
                      <span className="text-neutral-400">•</span>
                      <span className="text-neutral-300">{announcement.company_name}</span>
                    </div>
                    <div className="flex gap-2">
                      <Badge variant="outline">{announcement.category}</Badge>
                      <Badge className={sentimentColors[announcement.sentiment.toLowerCase()]}>
                        {announcement.sentiment}
                      </Badge>
                    </div>
                  </div>
                  <span className="text-sm text-neutral-500">
                    {format(parseISTDate(announcement.created_at), "MMM d, h:mm a 'IST'")}
                  </span>
                </div>
                <div className="prose prose-invert max-w-none whitespace-pre-line">
                  {announcement.summary}
                </div>
                
                {/* Add URL link */}
                {announcement.url && (
                  <div className="mt-3">
                    <a 
                      href={announcement.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-300 text-sm"
                    >
                      Read more at source →
                    </a>
                  </div>
                )}

                {/* Add disclaimer */}
                <div className="mt-3 text-xs text-neutral-500 italic">
                  Note: The summary and sentiment analysis are AI-generated. Please conduct your own research and due diligence before making any decisions.
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-6 flex items-center justify-between">
            <div className="text-sm text-neutral-400">
              Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, announcements?.totalCount || 0)} of {announcements?.totalCount || 0} results
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(p => p + 1)}
                disabled={!announcements?.data || currentPage * pageSize >= announcements.totalCount}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AnnouncementsTable; 