import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: theme.spacing(12, 0),
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  overflow: "hidden",
}));

const StyledImage = styled("img")({
  width: "100%",
  height: "400px",
  objectFit: "cover",
  borderRadius: "8px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
});

const StyledListItem = styled(ListItem)({
  padding: "8px 0",
});

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: "30px",
});

const StyledListItemText = styled(ListItemText)({
  margin: 0,
});

const FeatureCard = styled(Box)(({ theme }) => ({
  background: "linear-gradient(to bottom, #1e1e1e, #2a2a2a)",
  borderRadius: "24px",
  padding: theme.spacing(6),
  width: '100%',
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const StyledSlider = styled(Slider)({
  "& .slick-dots li button:before": {
    color: "#6366F1",
  },
  "& .slick-dots li.slick-active button:before": {
    color: "#6366F1",
  },
});

const FeatureBlock = ({ title, description, gifUrl, reverse, id }) => (
  <FeatureCard id={id}>
    <Grid 
      container 
      spacing={6} 
      alignItems="center" 
      direction={reverse ? "row-reverse" : "row"}
    >
      <Grid item xs={12} md={6}>
        <motion.div
          initial={{ opacity: 0, x: reverse ? 50 : -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              background: "linear-gradient(45deg, #6366F1, #4F46E5)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              mb: 3,
            }}
          >
            {title}
          </Typography>
          <Typography 
            variant="h6" 
            color="grey.300"
            sx={{ lineHeight: 1.8 }}
          >
            {description}
          </Typography>
        </motion.div>
      </Grid>
      <Grid item xs={12} md={6}>
        <motion.div 
          whileHover={{ scale: 1.02 }}
          initial={{ opacity: 0, x: reverse ? -50 : 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <Box
            component="img"
            src={gifUrl}
            alt={title}
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: '16px',
              boxShadow: '0 20px 40px rgba(0,0,0,0.3)',
            }}
          />
        </motion.div>
      </Grid>
    </Grid>
  </FeatureCard>
);

const UpcomingFeatureCard = styled(Box)(({ theme }) => ({
  background: "linear-gradient(to bottom, #1a2333, #1e1e1e)",
  borderRadius: "16px",
  padding: theme.spacing(3),
  height: '100%',
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
  transition: "transform 0.3s ease",
  position: 'relative',
  "&:hover": {
    transform: "translateY(-8px)",
  },
  "&::before": {
    content: '"Coming Soon"',
    position: 'absolute',
    top: '12px',
    right: '12px',
    background: 'linear-gradient(45deg, #64ffda, #48cae4)',
    padding: '4px 12px',
    borderRadius: '12px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#1a1a1a',
  }
}));

const UpcomingFeatureBlock = ({ title, description }) => (
  <UpcomingFeatureCard>
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            background: "linear-gradient(45deg, #64ffda, #48cae4)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginBottom: 2,
            pr: 8,
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: '#a8b2d1',
            lineHeight: 1.6 
          }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  </UpcomingFeatureCard>
);

const Features = () => {
  const features = [
    {
      id: 'concall-summaries',
      title: "Concall Summaries",
      description: "Get instant access to AI-generated summaries of earnings calls, highlighting key metrics, strategic initiatives, and management insights.",
      gifUrl: "/concall-summary.gif",
    },
    {
      id: 'chat-announcements',
      title: "Chat with Announcements",
      description: "Interact naturally with company announcements through our AI chat interface. Ask questions and get immediate, contextual responses.",
      gifUrl: "/announcement-chat.gif",
    },
    {
      id: 'investor-presentation',
      title: "Chat Across Investor Presentation & Concall",
      description: "Seamlessly navigate and query information across multiple investor documents, connecting insights from various sources.",
      gifUrl: "/investor-chat.gif",
    },
    {
      id: 'announcement-summaries',
      title: "Announcement Summaries",
      description: "Stay updated with concise, AI-powered summaries of company announcements, helping you catch important updates quickly.",
      gifUrl: "/announcement-summary.gif",
    },
    {
      id: 'deep-dive',
      title: "Deep Dive Reports",
      description: "Access comprehensive analysis reports that combine multiple data sources to provide in-depth insights into company performance.",
      gifUrl: "/deep-dive.gif",
    },
  ];

  const upcomingFeatures = [
    {
      title: "Watchlist News",
      description: "Stay informed with personalized news feeds for your watchlist companies, filtered and summarized for relevance.",
    },
    {
      title: "Government News Summaries",
      description: "Get AI-powered summaries of government policies and regulatory changes that impact market movements.",
    },
    {
      title: "Chat with Annual Reports",
      description: "Interact with comprehensive annual reports through natural conversation, extracting key insights instantly.",
    },
    {
      title: "Credit Rating Analysis",
      description: "Access detailed credit rating assessments and historical analysis for informed investment decisions.",
    },
    {
      title: "Brokerage Report Summaries",
      description: "Get concise summaries of brokerage reports with key recommendations and analysis highlights.",
    },
  ];

  return (
    <StyledBox>
      <Helmet>
        <title>Features - CompoundingAI</title>
        <meta name="description" content="Explore AI-powered financial analytics features including concall summaries, announcement analysis, deep dive reports, and more." />
      </Helmet>
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          component="h1"
          align="center"
          gutterBottom
          sx={{ 
            mb: 12, 
            fontWeight: "bold",
            background: "linear-gradient(45deg, #6366F1, #4F46E5)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          AI-Powered Financial Analytics
        </Typography>
        
        <Stack spacing={12}>
          {features.map((feature, index) => (
            <FeatureBlock 
              key={index} 
              {...feature} 
              reverse={index % 2 !== 0}
            />
          ))}
        </Stack>

        <Box sx={{ mt: 16, mb: 8 }}>
          <Typography
            variant="h3"
            align="center"
            sx={{ 
              mb: 8, 
              background: "linear-gradient(45deg, #64ffda, #48cae4)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold" 
            }}
          >
            Much More Coming Soon
          </Typography>
          
          <Grid container spacing={4}>
            {upcomingFeatures.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <UpcomingFeatureBlock {...feature} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </StyledBox>
  );
};

export default Features;

