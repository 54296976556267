import { Badge } from "../../ui/badge.tsx";
import { Skeleton } from "../../ui/skeleton.tsx";

export const ConcallSummary = ({ data, isLoading, error, NoDataComponent }) => {
  if (isLoading) {
    return <Skeleton className="w-full h-full" />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-red-400 text-sm">Failed to load concall summary. Please try again later.</p>
      </div>
    );
  }

  if (!data?.categories?.length) {
    return <NoDataComponent />;
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 overflow-y-auto">
        <div className="p-6 space-y-6">
          {data?.categories?.map((category) => (
            <div key={category.name} className="border border-neutral-800 rounded-lg p-4">
              <div className="flex items-center gap-2 mb-3">
                <h3 className="text-lg font-medium text-neutral-200">{category.name}</h3>
                <Badge className={`${
                  category.sentiment === 'positive' ? 'bg-green-500/20 text-green-500' :
                  category.sentiment === 'negative' ? 'bg-red-500/20 text-red-500' :
                  category.sentiment === 'cautionary' ? 'bg-yellow-500/20 text-yellow-500' :
                  'bg-blue-500/20 text-blue-500'
                }`}>
                  {category.sentiment}
                </Badge>
              </div>
              <div className="prose prose-invert max-w-none">
                <p className="text-sm text-neutral-300 whitespace-pre-line">
                  {category.summary}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="sticky bottom-0 bg-[#000300] text-xs text-neutral-500 italic p-4 border-t border-neutral-700">
        Note: The summary and sentiment analysis are AI-generated. Please conduct your own research and due diligence before making any decisions.
      </div>
    </div>
  );
}; 