import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import MessageInput from "./chat/MessageInput";
import MessageSources, { renderSourceMarkdown } from "./chat/MessageSources";
import FollowUpQueries from "./chat/FollowUpQueries";
import { useAuth } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import {
  ThumbsUp,
  ThumbsDown,
  Sparkles,
  MessageCircle,
  MessagesSquare,
  User,
  Bot,
} from "lucide-react";
import { useLocation } from "react-router-dom";

import ChatFilter, {
  yearFilters,
  quarterFilters,
  sourceFilters,
} from "./chat/ChatFilter";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "@mui/material";
import api from "../config/axios";
import { Switch } from "./ui/switch.tsx";
import { supabase } from "../supabaseClient.js";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog.tsx";
import ExportPDF from './chat/ExportPDF';

const questions = [
  "What risks are automotive components companies facing?",
  "Which companies are talking about margin expansion in upcoming quarters?",
  "Which companies supply automotive components to Tata Motors and what are they saying about their inventory levels?",
];
const API_URL = process.env.REACT_APP_API_URL || "https://compoundingai.in";

const getFormattedUrl = (content) => {
  if (!content) return "";

  if (content.includes("storage.googleapis.com")) {
    return content;
  }

  return `${API_URL}/${content.startsWith("/") ? content.slice(1) : content}`;
};

const extractTables = (content) => {
  const segments = [];
  const lines = content.split('\n');
  let currentSegment = { type: 'text', content: [] };
  let tableStarted = false;
  let headerFound = false;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const isTableRow = line.trim().startsWith('|') && line.trim().endsWith('|');
    const isHeaderSeparator = line.includes('|-') && isTableRow;

    if (isTableRow) {
      if (!tableStarted) {
        // Save previous text segment if it exists
        if (currentSegment.content.length > 0) {
          segments.push({
            type: 'text',
            content: currentSegment.content.join('\n')
          });
        }
        // Start new table segment
        currentSegment = { type: 'table', content: [line] };
        tableStarted = true;
      } else {
        currentSegment.content.push(line);
      }
      
      if (isHeaderSeparator) {
        headerFound = true;
      }
    } else {
      if (tableStarted && headerFound) {
        // End table segment
        segments.push({
          type: 'table',
          content: currentSegment.content.join('\n')
        });
        currentSegment = { type: 'text', content: [line] };
        tableStarted = false;
        headerFound = false;
      } else {
        if (tableStarted) {
          // If we thought it was a table but no header was found, treat it as text
          currentSegment.type = 'text';
        }
        currentSegment.content.push(line);
      }
    }
  }

  // Add the last segment
  if (currentSegment.content.length > 0) {
    segments.push({
      type: currentSegment.type,
      content: Array.isArray(currentSegment.content) 
        ? currentSegment.content.join('\n') 
        : currentSegment.content
    });
  }

  return segments;
};

const convertTableToCSV = (headers, data) => {
  const csvRows = [];
  
  // Process headers - preserve bold formatting with Excel-compatible markers
  const processedHeaders = headers.map(header => 
    header.replace(/\*\*(.*?)\*\*/g, '$1') // Remove markdown bold markers
  );
  csvRows.push(processedHeaders.join(','));
  
  // Process data rows - preserve formatting with Excel-compatible markers
  data.forEach(row => {
    const processedRow = row.map(cell => {
      // Preserve bold text with Excel rich text format
      const processedCell = cell.replace(/\*\*(.*?)\*\*/g, (_, text) => text);
      
      // Escape cells that contain commas, quotes, or newlines
      const escaped = processedCell.replace(/"/g, '""');
      return cell.includes(',') || cell.includes('"') || cell.includes('\n') 
        ? `"${escaped}"` 
        : escaped;
    });
    csvRows.push(processedRow.join(','));
  });
  
  return csvRows.join('\n');
};

const MessageTable = ({ tableContent }) => {
  const rows = tableContent.trim().split('\n');
  
  const headers = rows[0]
    .split('|')
    .filter(cell => cell.trim())
    .map(cell => cell.trim().replace(/\*\*/g, ''));
  
  const data = rows.slice(2).map(row => 
    row.split('|')
      .filter(cell => cell.trim())
      .map(cell => cell.trim())
  );

  const handleDownloadCSV = () => {
    // Create Excel-compatible UTF-8 BOM
    const BOM = '\uFEFF';
    const csv = BOM + convertTableToCSV(headers, data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    link.setAttribute('href', url);
    link.setAttribute('download', `table_data_${timestamp}.csv`);
    link.style.visibility = 'hidden';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Helper function to determine if content needs markdown
  const needsMarkdown = (content) => {
    return content.includes('[') || 
           content.includes('**') || 
           content.includes('\n') ||
           content.includes('http');
  };

  // Add citation formatting function
  const formatCitations = (content, sources) => {
    return content.replace(
      /\[(\d+)\]/g,
      (match, num) => {
        const source = sources?.[num];
        if (!source) return match;
        
        return `<span 
          class="source-citation" 
          data-source-content="${encodeURIComponent(JSON.stringify(source))}"
        >[${num}]</span>`;
      }
    );
  };

  return (
    <div className="overflow-x-auto my-4">
      <div className="flex justify-end mb-2">
        <button
          onClick={handleDownloadCSV}
          className="px-3 py-1 text-sm bg-neutral-800 hover:bg-neutral-700 
                     text-neutral-200 rounded flex items-center gap-1 transition-colors"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
            <polyline points="7 10 12 15 17 10" />
            <line x1="12" y1="15" x2="12" y2="3" />
          </svg>
          Download CSV
        </button>
      </div>
      <table className="min-w-full border-collapse">
        <thead className="bg-neutral-800">
          <tr>
            {headers.map((header, i) => (
              <th 
                key={i} 
                className="border border-neutral-700 px-4 py-2 text-left font-bold"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={i} className="border-b border-neutral-700">
              {row.map((cell, j) => (
                <td 
                  key={j} 
                  className="border border-neutral-700 px-4 py-2 align-top break-words"
                  style={{ 
                    minWidth: '150px',
                    maxWidth: '400px'
                  }}
                >
                  {needsMarkdown(cell) ? (
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      className="text-base leading-7"
                      components={{
                        p: ({ children }) => <span>{children}</span>,
                        a: ({ node, ...props }) => (
                          <a 
                            {...props} 
                            className="text-blue-400 hover:text-blue-300 underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        span: ({ node, ...props }) => <span {...props} />
                      }}
                    >
                      {formatCitations(cell, {})}
                    </ReactMarkdown>
                  ) : (
                    cell
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const formatMessage = (message) => {
  if (message.role === "assistant" && typeof message.content === "string") {
    let formattedContent = message.content;
    
    // Handle citations
    formattedContent = formattedContent.replace(
      /\[(\d+)\]/g,
      (match, num) => {
        const source = message.sources?.[num];
        if (!source) return match;

        const sourceLabel = `${source.type === "concall" ? "Earnings Call" : "Presentation"} ${source.ticker || ""} ${source.quarter || ""} ${source.year || ""}`;
        return `<span 
                  class="source-citation source-${message.messageId}-${num} cursor-pointer hover:text-blue-400"
                  data-dialog-trigger="${num}"
                  data-message-id="${message.messageId}"
                  data-source-type="${source.type}"
                  data-source-content="${encodeURIComponent(JSON.stringify(source))}"
                >[${num}]</span>`;
      }
    );

    // Handle table formatting - remove segment reference
    formattedContent = formattedContent.replace(/\|-+\|/g, '|-|');
    formattedContent = formattedContent.replace(/\|(\S)/g, '| $1');
    formattedContent = formattedContent.replace(/(\S)\|/g, '$1 |');

    return formattedContent;
  }
  return message.content;
};

const MessageContent = ({ message }) => {
  // Add this regex processing before extracting tables
  const cleanedContent = message.content.replace(
    /^(#+)\s*\*\*(.*?)\*\*(\s*\[\d+\]+\s*)+$/gm,
    '$1 **$2**'
  );

  const segments = extractTables(cleanedContent);
  
  return (
    <div className="prose prose-invert max-w-none">
      {segments.map((segment, index) => {
        if (segment.type === 'table') {
          return <MessageTable key={index} tableContent={segment.content} />;
        }
        
        if (segment.content.includes('|') && segment.content.includes('|-')) {
          return null;
        }

        // Format citations only in body content
        const formattedContent = segment.content.replace(
          /\[(\d+)\]/g,
          (match, num) => {
            const source = message.sources?.[num];
            if (!source) return match;
            
            return `<span 
              class="source-citation" 
              data-source-content="${encodeURIComponent(JSON.stringify(source))}"
            >[${num}]</span>`;
          }
        );
        
        return (
          <ReactMarkdown
            key={index}
            rehypePlugins={[rehypeRaw]}
            className="text-base leading-7"
            components={{
              table: () => null,
              thead: () => null,
              tbody: () => null,
              tr: () => null,
              th: () => null,
              td: () => null,
              span: ({ node, ...props }) => <span {...props} />
            }}
          >
            {formattedContent}
          </ReactMarkdown>
        );
      })}
    </div>
  );
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessageLoading, setNewMessageLoading] = useState(false);
  const [messageAppeared, setMessageAppeared] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const messageEnd = useRef(null);
  const bufferRef = useRef("");
  const { sessionId: urlSessionId } = useParams();
  const [title, setTitle] = useState("New Chat");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { session } = useAuth();
  const accessToken = session?.access_token;

  const location = useLocation();
  const [selectedQuarter, setSelectedQuarter] = useState('All');
  const [selectedYear, setSelectedYear] = useState('All');

  // Clear messages when URL changes
  useEffect(() => {
    // Clear messages immediately when URL changes
    setMessages([]);
    setMessageAppeared(false);

    if (urlSessionId === undefined) {
      setSessionId(null);
      setTitle("New Chat");
    }
  }, [urlSessionId]);

  // Cleanup buffer on unmount
  useEffect(() => {
    return () => {
      bufferRef.current = "";
    };
  }, []);

  // Load session data
  useEffect(() => {
    const initSession = async () => {
      try {
        if (!accessToken || !urlSessionId) return;

        // Only fetch session data if we have a valid session ID
        if (urlSessionId !== "null") {
          try {
            setLoading(true); // Show loading state
            const response = await api.get(`/chat/session/${urlSessionId}`);

            if (response.data) {
              setSessionId(urlSessionId);
              setTitle(response.data.title || "New Chat");

              // Transform and set messages only if we have valid data
              if (response.data.messages && response.data.messages.length > 0) {
                const formattedMessages = response.data.messages.map((msg) => ({
                  content:
                    msg.role === "assistant"
                      ? msg.generated_answer
                      : msg.user_query,
                  messageId: msg.message_id,
                  role: msg.role,
                  sources: msg.sources ? JSON.parse(msg.sources) : {},
                  followupQueries: msg.followup_queries
                    ? JSON.parse(msg.followup_queries)
                    : [],
                  createdAt: msg.created_at,
                  thumbsUp: Boolean(msg.thumbs_up),
                  thumbsDown: Boolean(msg.thumbs_down),
                }));
                setMessages(formattedMessages);
              }
            }
          } catch (error) {
            console.error("Session error:", error);
            if (error.response?.status === 404) {
              navigate("/chat", { replace: true });
            }
          } finally {
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error in initSession:", error);
        setLoading(false);
      }
    };

    initSession();
  }, [accessToken, urlSessionId]);

  useEffect(() => {
    if (location.pathname.split("/")[2]) {
      messageEnd.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const processSourceValue = (value) => {
    try {
      // If it's already an object, return it
      if (typeof value === "object" && value !== null) {
        return value;
      }

      // If it's a string, try to parse it
      if (typeof value === "string") {
        // Replace single quotes with double quotes for JSON parsing
        return JSON.parse(value.replace(/'/g, '"'));
      }

      return null;
    } catch (e) {
      console.error("Error processing source value:", e, "Value:", value);
      return null;
    }
  };

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  // Add this state to track streaming completion
  const [isStreaming, setIsStreaming] = useState(false);

  const sendMessage = async (message, tools = []) => {
    if (!message.trim()) return;

    try {
      setLoading(true);
      setMessageAppeared(false);
      bufferRef.current = "";

      let currentSessionId = sessionId;
      let isNewSession = false;

      // Only create new session if this is a new chat (no sessionId)
      // AND this is the first message being sent
      if (!currentSessionId && messages.length === 0) {
        try {
          const sessionResponse = await api.post("/chat/session", {
            title: message.slice(0, 50) + (message.length > 50 ? "..." : ""),
          });

          if (!sessionResponse.data?.session_id) {
            throw new Error("Failed to create session");
          }

          currentSessionId = sessionResponse.data.session_id;
          setSessionId(currentSessionId);
          isNewSession = true;
        } catch (error) {
          console.error("Error creating session:", error);
          throw error;
        }
      }

      // Create properly formatted user message
      const newUserMessage = {
        content: message,
        messageId: `user-${Date.now()}`,
        role: "user",
        createdAt: new Date(),
        sources: {},
        followupQueries: [],
        thumbsUp: false,
        thumbsDown: false,
        tools: tools.tools || [], // Extract tools array
      };

      // Add message immediately for better UX
      setMessages((prev) => [...prev, newUserMessage]);

      // Ensure we have a valid session ID before making the chat request
      if (!currentSessionId) {
        throw new Error("No valid session ID available");
      }

      setNewMessageLoading(true);
      setIsStreaming(true);
      
      // Modified API request to include filters
      const response = await fetch(`${API_URL}/api/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          session_id: currentSessionId,
          messages: [...messages, newUserMessage],
          tools: tools.tools || [],
          filters: {
            quarter: tools.quarter || 'All',
            financial_year: tools.financialYear || 'All'
          }
        }),
      });
      setNewMessageLoading(false);
      navigate(`/chat/${currentSessionId}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Network response was not ok");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedData = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          setIsStreaming(false);
          break;
        }

        const text = decoder.decode(value);
        bufferRef.current += text;
        
        // Process complete JSON objects
        while (bufferRef.current.includes("\n")) {
          const newlineIndex = bufferRef.current.indexOf("\n");
          const jsonString = bufferRef.current.slice(0, newlineIndex);
          bufferRef.current = bufferRef.current.slice(newlineIndex + 1);

          try {
            const data = JSON.parse(jsonString);
            
            // Ensure we have all required data before updating state
            if (data.answer && data.interaction_id) {
              const assistantMessage = {
                content: data.answer,
                messageId: data.interaction_id,
                role: "assistant",
                sources: data.citations || {},
                followupQueries: data.followup_queries || [],
                createdAt: new Date(),
                thumbsUp: false,
                thumbsDown: false,
              };

              setMessages(prev => {
                const existingMessageIndex = prev.findIndex(
                  msg => msg.messageId === data.interaction_id
                );

                if (existingMessageIndex === -1) {
                  // New message
                  return [...prev, assistantMessage];
                } else {
                  // Update existing message
                  const newMessages = [...prev];
                  newMessages[existingMessageIndex] = assistantMessage;
                  return newMessages;
                }
              });

              // Set citations ready only when we have complete data
              setCitationsReady(true);
            }
          } catch (e) {
            console.error("Error parsing streaming data:", e);
          }
        }
      }

      // Only invalidate chat history if this was a new session
      if (isNewSession) {
        queryClient.invalidateQueries(["chat-history"]);
      }
    } catch (error) {
      console.error("Error in sendMessage:", error);
    } finally {
      setIsStreaming(false);
      setLoading(false);
    }
  };

  // Add state to track if citations are ready
  const [citationsReady, setCitationsReady] = useState(false);

  // Add click handler for citations
  useEffect(() => {
    const handleCitationClick = (e) => {
      const citation = e.target.closest(".source-citation");
      if (!citation?.dataset?.sourceContent) return;

      try {
        const sourceContent = JSON.parse(decodeURIComponent(citation.dataset.sourceContent));
        
        // Handle announcements differently
        if (sourceContent.type === 'announcement') {
          window.open(sourceContent.url, '_blank');
          return;
        }
        
        // Create Dialog programmatically for other types
        const dialog = document.createElement('div');
        dialog.className = 'fixed inset-0 z-50 flex items-center justify-center';
        dialog.innerHTML = `
          <div class="fixed inset-0 bg-black/50"></div>
          <div class="relative bg-neutral-900 border-neutral-800 font-terminal lg:max-w-[60svw] md:max-w-[80svw] p-4 text-neutral-300">
            <div class="border-b border-neutral-800 pb-2 mb-4">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2 text-gray-200">
                  <span class="text-gray-400">
                    ${sourceContent.type === 'concall' ? '<svg class="w-4 h-4">...</svg>' : '<svg class="w-4 h-4">...</svg>'}
                  </span>
                  <span class="font-medium">
                    ${sourceContent.type === 'concall' ? 'Earnings Call' : 'Presentation'} 
                    ${sourceContent.ticker || ''} ${sourceContent.quarter || ''} ${sourceContent.year || ''}
                  </span>
                </div>
                <button class="close-dialog text-gray-400 hover:text-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
              </div>
            </div>
            ${
              sourceContent.type === 'slide' 
                ? `<img src="${getFormattedUrl(sourceContent.content)}" alt="Slide" class="w-full h-full min-w-[40svw] max-h-[80svh] object-fit">`
                : `<div class="max-h-[60svh] overflow-y-auto">${sourceContent.content.replace(/.*Text:\s*/, '')}</div>`
            }
          </div>
        `;

        document.body.appendChild(dialog);

        // Add click handlers
        const closeBtn = dialog.querySelector('.close-dialog');
        const handleClose = (e) => {
          if (e.target === dialog || e.target.closest('.close-dialog')) {
            dialog.remove();
            document.removeEventListener('keydown', handleEscape);
          }
        };
        
        const handleEscape = (e) => {
          if (e.key === 'Escape') {
            dialog.remove();
            document.removeEventListener('keydown', handleEscape);
          }
        };

        dialog.addEventListener('click', handleClose);
        document.addEventListener('keydown', handleEscape);
      } catch (error) {
        console.error("Error handling citation:", error);
      }
    };

    document.addEventListener("click", handleCitationClick);
    return () => {
      document.removeEventListener("click", handleCitationClick);
      document.querySelectorAll(".transcript-tooltip").forEach(el => el.remove());
    };
  }, []);

  const handleThumbs = async (messageId, isThumbsUp) => {
    if (!messageId) {
      console.error("No messageId provided for thumbs update");
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/api/chat/interaction/${messageId}/thumbs`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            thumbs_up: isThumbsUp,
            thumbs_down: !isThumbsUp,
          }),
        },
      );

      const data = await response.json();

      if (!response.ok) {
        console.error("Error response:", data);
        throw new Error(data.detail || "Failed to update thumbs");
      }

      // Update the UI - convert integers to boolean for display
      setMessages((prev) =>
        prev.map((msg) =>
          msg.messageId === messageId
            ? {
                ...msg,
                thumbsUp: Boolean(data.data?.thumbs_up),
                thumbsDown: Boolean(data.data?.thumbs_down),
              }
            : msg,
        ),
      );
    } catch (error) {
      console.error("Error updating thumbs:", error);
    }
  };

  // Don't render anything until we have both session and token
  if (!accessToken) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full chat-container">
      <div className="border-b border-neutral-700 p-4 chat-header">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-mono text-terminal-text line-clamp-1">
            {title}
          </h1>
          {messages.length > 0 && (
            <ExportPDF messages={messages} title={title} />
          )}
        </div>
      </div>

      {(messages.length !== 0 || location.pathname.split("/")[2]) && (
        <div className="flex-1 overflow-auto p-4 space-y-4">
          {messages.map((message) => (
            <div
              key={`${message.messageId}-${message.role}`}
              className="flex flex-col w-full"
            >
              <div 
                className={`flex w-full ${
                  message.role === "user" ? "justify-end w-full" : "justify-start w-full"
                }`}
              >
                <div
                  className={`chat-message ${
                    message.role === "user"
                      ? "chat-message-user"
                      : "chat-message-assistant"
                  }`}
                >
                  {message.role === "assistant" && (
                    <div className="assistant-avatar">
                      <Bot size={18} color="white" />
                    </div>
                  )}
                  <div className="message-content">
                    {message.role === "user" ? (
                      <div className="text-[0.95rem]">{message.content}</div>
                    ) : (
                      <div className="text-base">
                        <MessageContent message={message} />
                      </div>
                    )}
                    {message.role === "assistant" && message.sources && (
                      <MessageSources sources={message.sources} />
                    )}
                    {message.role === "assistant" && message.followupQueries && (
                      <FollowUpQueries
                        queries={message.followupQueries}
                        onQueryClick={(query) => sendMessage(query)}
                      />
                    )}
                    {message.role === "assistant" && (
                      <div className="flex space-x-2 mt-2">
                        <button
                          onClick={() => handleThumbs(message.messageId, true)}
                          className={`p-1 hover:bg-green-600 transition-colors ${
                            message.thumbsUp ? "bg-green-500" : "bg-neutral-700"
                          }`}
                        >
                          <ThumbsUp className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleThumbs(message.messageId, false)}
                          className={`p-1 hover:bg-red-600 transition-colors ${
                            message.thumbsDown ? "bg-red-500" : "bg-neutral-700"
                          }`}
                        >
                          <ThumbsDown className="h-5 w-5" />
                        </button>
                      </div>
                    )}
                  </div>
                  {message.role === "user" && (
                    <div className="user-avatar">
                      <User size={18} color="white" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          {newMessageLoading && (
            <Skeleton
              variant="rectangular"
              width="85%"
              className="chat-message p-4 font-mono"
            >
              Processing...
            </Skeleton>
          )}
          <div ref={messageEnd} />
        </div>
      )}
      {!location.pathname.split("/")[2] && messages.length === 0 && (
        <div className="h-full flex flex-col justify-center">
          <div className="flex bg-neutral-800 border border-neutral-700 max-w-[30rem] mx-auto  flex-col items-start gap-2 p-2 max-h-[50svh] overflow-y-auto text-sm">
            <h1 className="text-xl font-sans  font-semibold text-center p-2 w-full">
              <MessagesSquare size={24} className="inline-block mr-2 " />
              Ask company related questions
            </h1>
            <div className="flex  flex-col  gap-2  h-full items-center justify-center">
              {questions.map((question, index) => (
                <button
                  key={index}
                  onClick={() => sendMessage(question)}
                  className="p-2  transition-colors bg-neutral-900 hover:text-neutral-400 px-4 text-start w-full"
                >
                  {question}
                </button>
              ))}
            </div>
          </div>
        </div>
        //   <div className="relative flex  flex-col items-start gap-2  p-4">
        //     {questions.map((question, index) => (
        //       <button
        //         key={index}
        //         onClick={() => sendMessage(question)}
        //         className="p-2 bg-neutral-800 border border-neutral-700 text-white hover:bg-neutral-700 transition-colors"
        //       >
        //         {question}
        //       </button>
        //     ))}
        //   </div>
      )}
      <div className="chat-input-container items-center gap-3 p-4">
        <MessageInput sendMessage={sendMessage} loading={loading} />
      </div>
    </div>
  );
};

export default Chat;
