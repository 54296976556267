import api from "../../../config/axios";

export const fetchReportStatus = async (ticker, category) => {
  const response = await api.get(`/report/status`, {
    params: { ticker, category }
  });
  return response.data;
};

export const initiateReport = async (ticker, category) => {
  const response = await api.post("/report/generate", {
    ticker,
    category
  });
  return response.data;
};

export const fetchReport = async (ticker, category) => {
  // First check if report exists
  try {
    const response = await api.get(`/report`, {
      params: { ticker, category }
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      // Report doesn't exist, initiate generation
      await initiateReport(ticker, category);
      throw new Error('REPORT_GENERATING');
    }
    throw error;
  }
}; 