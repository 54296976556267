import React from "react";
import { Navigate } from "react-router-dom";
import PrivateLayout from "./PrivateLayout";
import { useAuth } from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
  const { session, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center bg-neutral-900">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  if (!session?.access_token) {
    return <Navigate to="/login" replace />;
  }

  return <PrivateLayout>{children}</PrivateLayout>;
};

export default PrivateRoute;
