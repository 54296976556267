import { useState } from "react";
import SearchInput from "./SearchInput";

const Search = () => {
  return (
    <div className="flex flex-col h-full chat-container items-center mt-10 space-y-2">
      <h1 className="text-2xl font-medium">Search Companies for deep dive</h1>
      <SearchInput className="" />
    </div>
  );
};

export default Search;
