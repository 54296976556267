import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { supabase } from "../supabaseClient";
import { StyledButton, StyledContainer } from "./Waitlist";
import styled from "@emotion/styled";

export const StyledOutlinedButton = styled(Button)(({ theme }) => ({
  // background: "#4F46E5", // Indigo-600
  color: "white",
  border: "1px solid #4F46E5",
  padding: "8px 16px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  "&:hover": {
    background: "#4338CA", // Indigo-700
    transform: "translateY(-2px)",
    border: "1px solid #4F46E5",
    boxShadow: `0 4px 12px ${theme.palette.custom.glow}`,
  },
}));
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { session } = useAuth();

  // Redirect if already logged in
  React.useEffect(() => {
    if (session) {
      navigate("/chat");
    }
  }, [session, navigate]);

  const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "github",
    });
    if (error) console.error("Sign in with Google failed:", error.message);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous errors

    try {
      // Authenticate with Supabase only
      const { data, error: supabaseError } =
        await supabase.auth.signInWithPassword({
          email,
          password,
        });

      if (supabaseError) {
        throw new Error(supabaseError.message);
      }

      if (data.session) {
        // Store the token
        localStorage.setItem("token", data.session.access_token);
        console.log("Login successful, redirecting...");
        navigate("/chat", { replace: true });
      } else {
        throw new Error("No session data received");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError(error.message || "An error occurred during login.");
    }
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            className="focus:border-indigo-600"
            onChange={(e) => setPassword(e.target.value)}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </StyledButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="text-neutral-400 text-sm font-medium w-full flex items-center justify-evenly mb-2">
              <hr className="w-full border-neutral-500" />
              <div className="w-48 px-2 text-center">Login with</div>
              <hr className="w-full border-neutral-500" />
            </div>
            <ButtonGroup
              variant="outlined"
              aria-label="Basic button group"
              height="10rem"
              fullWidth
            >
              {
                <StyledOutlinedButton
                  onClick={signInWithGoogle}
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    padding: 1,
                  }}
                  className="border border-neutral-600 p-2 flex items-center gap-2 rounded-md w-full py-4"
                >
                  <img
                    src="/google_icon.svg"
                    alt="Google Logo"
                    className="red-50 w-5 h-5"
                  />
                  Sign in with Google
                </StyledOutlinedButton>
              }
            </ButtonGroup>
          </Box>
          <Link
            to="/register"
            className="flex items-center text-sm underline hover:text-indigo-400 justify-center mt-2 text-indigo-600"
          >
            {"Don't have an account? Sign Up"}
          </Link>
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default Login;
