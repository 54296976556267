import React, { useRef, useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ArrowUp, BarChart, MessageSquare, Filter } from "lucide-react";
import { supabase } from "../../supabaseClient.js";

// Define available tools
const AVAILABLE_TOOLS = [
  {
    id: 'announcements',
    name: 'Announcements',
    description: 'Search through company announcements',
    icon: <MessageSquare size={16} />
  },
  // {
  //   id: 'chart',
  //   name: 'Chart Generator',
  //   description: 'Generate charts from financial data',
  //   icon: <BarChart size={16} />
  // }
];

// Add this after AVAILABLE_TOOLS constant
const QUARTERS = ['All', 'Q1', 'Q2', 'Q3', 'Q4'];
const FINANCIAL_YEARS = ['All', 'FY 2024-2025', 'FY 2023-2024', 'FY 2022-2023'];

const MessageInput = ({ sendMessage, loading }) => {
  const [message, setMessage] = useState("");
  const [showToolSuggestions, setShowToolSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedTools, setSelectedTools] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showQuarters, setShowQuarters] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState('All');
  const [selectedYear, setSelectedYear] = useState('All');
  const inputRef = useRef(null);
  const suggestionRef = useRef(null);
  const filterRef = useRef(null);

  // Handle input changes and detect @ symbol
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
    
    // Get cursor position
    const cursorPos = e.target.selectionStart;
    setCursorPosition(cursorPos);
    
    // Check if we should show tool suggestions
    const textBeforeCursor = newValue.slice(0, cursorPos);
    const lastAtSymbolIndex = textBeforeCursor.lastIndexOf('@');
    
    if (lastAtSymbolIndex !== -1) {
      const textAfterAt = textBeforeCursor.slice(lastAtSymbolIndex + 1).toLowerCase();
      // Filter tools based on what's typed after @
      const filteredTools = AVAILABLE_TOOLS.filter(tool => 
        tool.name.toLowerCase().includes(textAfterAt)
      );
      
      // Only show suggestions if we have matches or nothing is typed after @
      if (filteredTools.length > 0 || textAfterAt === '') {
        setShowToolSuggestions(true);
      } else {
        setShowToolSuggestions(false);
      }
    } else {
      setShowToolSuggestions(false);
    }
  };

  // Handle tool selection
  const handleToolSelect = (tool) => {
    const beforeCursor = message.slice(0, cursorPosition);
    const afterCursor = message.slice(cursorPosition);
    
    // Replace the @ with the tool mention
    const newMessage = `${beforeCursor.slice(0, -1)}@${tool.name} ${afterCursor}`;
    setMessage(newMessage);
    setSelectedTools([...selectedTools, tool]);
    setShowToolSuggestions(false);
    
    // Focus back on input
    inputRef.current?.focus();
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loading && message.trim()) {
      // Send message with filters information
      sendMessage(message, {
        quarter: selectedQuarter,
        financialYear: selectedYear,
        tools: selectedTools
      });
      setMessage("");
      setSelectedTools([]);
    }
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
        setShowToolSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Add this useEffect for handling clicks outside filter dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleFilterSubmit = () => {
    setShowFilters(false);
    setShowQuarters(false);
    setShowYears(false);
    // Here we'll later add logic to use these filters
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="relative flex flex-col justify-center border-t border-neutral-700 bg-black"
    >
      <div className="max-w-48rem mx-auto w-full">
        <div className="flex items-center gap-2 p-4">
          <div className="relative">
            <button
              type="button"
              onClick={() => setShowFilters(!showFilters)}
              className="p-3 rounded-lg bg-neutral-800 text-white hover:bg-neutral-700 transition-colors"
            >
              <Filter size={20} />
            </button>
            
            {showFilters && (
              <div 
                ref={filterRef}
                className="absolute bottom-full left-0 mb-2 w-72 bg-neutral-800 border border-neutral-700 rounded-lg shadow-lg p-4"
              >
                <div className="space-y-4">
                  {/* Quarter Selection */}
                  <div className="relative">
                    <button
                      type="button"
                      onClick={() => {
                        setShowQuarters(!showQuarters);
                        setShowYears(false);
                      }}
                      className="w-full px-4 py-2 text-left bg-neutral-700 rounded-lg hover:bg-neutral-600 transition-colors flex justify-between items-center"
                    >
                      <span>Quarter: {selectedQuarter}</span>
                      <span className="text-xs">▼</span>
                    </button>
                    
                    {showQuarters && (
                      <div className="absolute top-full left-0 w-full mt-1 bg-neutral-800 border border-neutral-700 rounded-lg shadow-lg z-10">
                        {QUARTERS.map((quarter) => (
                          <button
                            key={quarter}
                            type="button"
                            onClick={() => {
                              setSelectedQuarter(quarter);
                              setShowQuarters(false);
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-neutral-700 transition-colors first:rounded-t-lg last:rounded-b-lg"
                          >
                            {quarter}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Financial Year Selection */}
                  <div className="relative">
                    <button
                      type="button"
                      onClick={() => {
                        setShowYears(!showYears);
                        setShowQuarters(false);
                      }}
                      className="w-full px-4 py-2 text-left bg-neutral-700 rounded-lg hover:bg-neutral-600 transition-colors flex justify-between items-center"
                    >
                      <span>Financial Year: {selectedYear}</span>
                      <span className="text-xs">▼</span>
                    </button>
                    
                    {showYears && (
                      <div className="absolute top-full left-0 w-full mt-1 bg-neutral-800 border border-neutral-700 rounded-lg shadow-lg z-10">
                        {FINANCIAL_YEARS.map((year) => (
                          <button
                            key={year}
                            type="button"
                            onClick={() => {
                              setSelectedYear(year);
                              setShowYears(false);
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-neutral-700 transition-colors first:rounded-t-lg last:rounded-b-lg"
                          >
                            {year}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Submit Button */}
                  <button
                    type="button"
                    onClick={handleFilterSubmit}
                    className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            )}
          </div>
          
          <div className="relative flex-1">
            <TextareaAutosize
              ref={inputRef}
              value={message}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              placeholder="Ask a question... (Type @ to use tools)"
              className="flex-1 max-h-32 p-3 bg-[#111111] rounded-lg resize-none focus:outline-none focus:ring-1 focus:ring-blue-500 text-[#ECECF1] placeholder-gray-400 w-full text-base leading-6"
              maxRows={5}
              disabled={loading}
            />
            
            {/* Tool suggestions popup */}
            {showToolSuggestions && (
              <div 
                ref={suggestionRef}
                className="absolute bottom-full left-0 mb-2 w-64 bg-neutral-800 border border-neutral-700 rounded-lg shadow-lg overflow-hidden"
              >
                {AVAILABLE_TOOLS.map((tool) => (
                  <button
                    key={tool.id}
                    onClick={() => handleToolSelect(tool)}
                    className="w-full px-4 py-2 flex items-center gap-2 hover:bg-neutral-700 text-left text-sm text-white"
                  >
                    {tool.icon}
                    <div>
                      <div className="font-medium">{tool.name}</div>
                      <div className="text-xs text-neutral-400">{tool.description}</div>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
          
          <button
            type="submit"
            disabled={loading || !message.trim()}
            className="p-3 rounded-lg bg-blue-600 text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 transition-colors"
          >
            <ArrowUp size={20} />
          </button>
        </div>
      </div>
    </form>
  );
};

export default MessageInput;
