import React from "react";
import { useAuth } from "../context/AuthContext";
import {
  SidebarProvider,
  SidebarTrigger,
  SidebarInset,
} from "./ui/sidebar.tsx";
import AppSidebar from "./AppSidebar";
import { useLocation } from "react-router-dom";

const PrivateLayout = ({ children }) => {
  const location = useLocation();
  const isFeedPage = location.pathname === '/feed';

  return (
    <SidebarProvider defaultCollapsed={isFeedPage}>
      <AppSidebar />
      <SidebarInset>
        <div className="flex items-center flex-col bg-black h-full">
          <main className="h-screen w-full container py-1 lg:px-32 md:px-16 px-4">
            {children}
          </main>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};

export default PrivateLayout;
