import React from 'react';
import { FileDown } from 'lucide-react';
import html2pdf from 'html2pdf.js';
import { marked } from 'marked';

const DeepDivePDF = ({ content, title, category, ticker, companyName }) => {
  const generatePDF = async () => {
    const container = document.createElement('div');
    container.style.padding = '20px';
    container.style.backgroundColor = 'white';
    container.style.color = 'black';
    container.style.fontFamily = 'system-ui, -apple-system, sans-serif';
    container.style.width = '180mm';
    container.style.margin = '0 auto';
    container.style.boxSizing = 'border-box';
    container.style.maxWidth = '100%';

    // Add CompoundingAI branding at the top
    const brandingHeader = document.createElement('div');
    brandingHeader.style.marginBottom = '20px';
    brandingHeader.style.display = 'flex';
    brandingHeader.style.flexDirection = 'column';
    brandingHeader.style.gap = '4px';

    // Main heading with "Powered by" text
    const mainHeading = document.createElement('div');
    mainHeading.style.display = 'flex';
    mainHeading.style.alignItems = 'center';
    mainHeading.style.gap = '8px';
    
    const poweredByText = document.createElement('span');
    poweredByText.style.fontSize = '14px';
    poweredByText.style.color = '#6B7280';
    poweredByText.textContent = 'Powered by';

    const companyNameSpan = document.createElement('span');
    companyNameSpan.style.fontSize = '16px';
    companyNameSpan.style.fontWeight = '600';
    companyNameSpan.style.color = '#4F46E5';
    companyNameSpan.textContent = 'CompoundingAI';

    mainHeading.appendChild(poweredByText);
    mainHeading.appendChild(companyNameSpan);
    
    const tagline = document.createElement('div');
    tagline.style.fontSize = '12px';
    tagline.style.color = '#6B7280';
    tagline.textContent = 'AI-Powered Research Assistant';
    
    const motto = document.createElement('div');
    motto.style.fontSize = '10px';
    motto.style.color = '#9CA3AF';
    motto.style.fontStyle = 'italic';
    motto.textContent = 'Maximizing shareholders value using AI';

    brandingHeader.appendChild(mainHeading);
    brandingHeader.appendChild(tagline);
    brandingHeader.appendChild(motto);
    container.appendChild(brandingHeader);

    // Add title section
    const titleSection = document.createElement('div');
    titleSection.style.marginBottom = '30px';
    titleSection.style.borderBottom = '2px solid #2563eb';
    titleSection.style.paddingBottom = '10px';
    titleSection.style.width = '100%';
    titleSection.style.maxWidth = '100%';

    const titleText = document.createElement('h1');
    titleText.style.fontSize = '24px';
    titleText.style.fontWeight = 'bold';
    titleText.style.color = '#1e293b';
    titleText.style.wordWrap = 'break-word'; // Enable word wrapping
    titleText.style.overflowWrap = 'break-word'; // Handle long words
    titleText.style.lineHeight = '1.4'; // Add some line height for wrapped text
    titleText.style.marginBottom = '8px'; // Space between title and timestamp
    titleText.textContent = `${companyName} - ${title}`;

    const timestamp = document.createElement('div');
    timestamp.style.fontSize = '12px';
    timestamp.style.color = '#64748b';
    timestamp.textContent = new Date().toLocaleString();

    titleSection.appendChild(titleText);
    titleSection.appendChild(timestamp);
    container.appendChild(titleSection);

    // Add content with improved spacing
    const contentDiv = document.createElement('div');
    contentDiv.style.fontSize = '14px';
    contentDiv.style.lineHeight = '1.8'; // Increased line height
    contentDiv.style.color = '#1e293b';
    contentDiv.style.width = '100%';
    contentDiv.style.maxWidth = '100%';
    contentDiv.style.wordWrap = 'break-word';
    contentDiv.style.overflowWrap = 'break-word';
    contentDiv.style.paddingRight = '10px'; // Add right padding

    // Add rating if available (before content)
    if (content.rating) {
      const ratingDiv = document.createElement('div');
      ratingDiv.style.marginBottom = '30px'; // Increased margin
      ratingDiv.style.fontSize = '16px';
      ratingDiv.style.fontWeight = '500';
      ratingDiv.textContent = `Rating: ${content.rating}`;
      container.appendChild(ratingDiv);
    }

    // Process markdown with custom spacing
    const processedContent = content.text
      // Add double line breaks between sections (headers)
      .replace(/\n(#{1,6} )/g, '\n\n\n$1')
      // Add double line breaks between paragraphs
      .replace(/\n\n/g, '\n\n\n')
      // Ensure proper spacing after lists
      .replace(/(\n- .*\n)(?=[^-])/g, '$1\n');

    // Convert markdown to HTML with custom styling
    const htmlContent = marked(processedContent, {
      breaks: true,
      gfm: true
    });

    // Add custom styles for better spacing
    contentDiv.innerHTML = `
      <style>
        h1, h2, h3, h4, h5, h6 {
          margin-top: 2em;
          margin-bottom: 1em;
          page-break-after: avoid;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
          padding-right: 10px;
        }
        p {
          margin-bottom: 1.5em;
          line-height: 1.8;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
          padding-right: 10px;
        }
        ul, ol {
          margin-top: 1em;
          margin-bottom: 1.5em;
          padding-left: 2em;
          padding-right: 10px;
          max-width: 100%;
        }
        li {
          margin-bottom: 0.8em;
          line-height: 1.6;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
          padding-right: 10px;
        }
        /* Prevent orphaned headers */
        h1, h2, h3, h4, h5, h6 {
          page-break-after: avoid;
        }
        /* Prevent orphaned list items */
        li {
          page-break-inside: avoid;
        }
        /* Prevent splitting of paragraphs across pages */
        p {
          page-break-inside: avoid;
        }
      </style>
      ${htmlContent}
    `;

    container.appendChild(contentDiv);

    // Add spacer for footer
    const spacer = document.createElement('div');
    spacer.style.height = '50px';
    container.appendChild(spacer);

    // Configure PDF options with adjusted margins and width
    const opt = {
      margin: [15, 15, 35, 15], // Reduced margins [top, right, bottom, left]
      filename: `${ticker}_${title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        backgroundColor: '#ffffff',
        windowWidth: 794, // Standard A4 width in pixels (roughly 210mm at 96dpi)
        letterRendering: true
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait',
        compress: false,
        putTotalPages: true,
        precision: 16
      },
      pagebreak: { 
        mode: ['avoid', 'css', 'legacy'],
        before: '.page-break-before',
        after: '.page-break-after',
        avoid: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li', 'p']
      },
      footer: {
        height: '28mm',
        contents: {
          default: `
            <div style="
              text-align: center;
              font-size: 10px;
              color: #6B7280;
              padding: 10px 20px;
              border-top: 1px solid #E5E7EB;
              background-color: white;
              margin-top: 20px;
              width: calc(100% - 40px);
            ">
              Generated by CompoundingAI | {{page}} of {{pages}}
            </div>
          `
        }
      }
    };

    try {
      document.body.appendChild(container);
      await html2pdf().set(opt).from(container).save();
      document.body.removeChild(container);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <button
      onClick={generatePDF}
      className="flex items-center gap-2 px-3 py-1.5 bg-neutral-800 hover:bg-neutral-700 rounded text-sm transition-colors"
      title="Export as PDF"
    >
      <FileDown size={16} />
      <span>Export PDF</span>
    </button>
  );
};

export default DeepDivePDF; 