import api from "../../../config/axios";

export const fetchConcallSummary = async (ticker, quarter, fy) => {
  const response = await api.get(`/concall/summary/${ticker}`, {
    params: { quarter, fy }
  });
  return response.data;
};

export const fetchConversationSummaries = async (ticker, quarter, fy) => {
  const response = await api.get(`/concall/conversations/${ticker}`, {
    params: { quarter, fy }
  });
  return response.data;
};

export const fetchConcallDocument = async (ticker, quarter, fy) => {
  const response = await api.get(`/concall/document/${ticker}`, {
    params: { quarter, fy }
  });
  return response.data;
}; 