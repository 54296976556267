import { useQueryClient } from "@tanstack/react-query";
import { Dot, Loader } from "lucide-react";
import React, { useState, useMemo, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useParams, Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug";
import { useQuery } from "@tanstack/react-query";
import { BLOG_API_URL } from "../../Environment";
import { supabase } from "../../supabaseClient";
import remarkGfm from 'remark-gfm';

export const generateSlug = (str) => {
  const regex = /##.*/g;
  let matches = str.match(regex);
  const slugs = matches.map((match) => {
    return match
      .replace(/^#+\s*/, "")
      .replace(/ /g, "-")
      .replace(/\./g, "")
      .toLowerCase();
  });
  matches = matches.map((match) => {
    // remove digits too and do
    return match
      .replace(/^#+\s*/, "")
      .replace(/\d/g, "")
      .replace(/\./g, "");
  });
  // return matches and slug
  return { matches, slugs };
};
const fetchBlog = async (id) => {
  const { data, error } = await supabase
    .from('blogs')
    .select('*')
    .eq('id', id)
    .single();

  if (error) throw error;
  return data;
};

const BlogSingle = () => {
  // memoize the slug and matches
  const { blogId } = useParams();

  const location = useLocation();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["blog", blogId],
    queryFn: () => fetchBlog(blogId),
  });
  const scrollToSection = (slug) => {
    const element = document.getElementById(slug);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const offset = -50; // Adjust this value to scroll a little bit above the center

      window.scrollTo({
        top: elementRect.top + window.pageYOffset + offset,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
    console.log("scrolling to top");
  }, [location]);
  const { matches, slugs } = useMemo(() => {
    if (!data) return { matches: [], slugs: [] };
    return generateSlug(data.content);
  });
  const cleanContent = (content) => {
    if (!content) return '';
    
    // Remove bold citations with backslashes pattern like **\[1]\[5]**
    const withoutBoldCitations = content.replace(/\*\*\\\[\d+\](?:\\\[\d+\])?\*\*/g, '');
    
    // Remove any remaining regular citations with backslashes \[1]\[5]
    const withoutCitations = withoutBoldCitations.replace(/\\\[\d+\](?:\\\[\d+\])?/g, '');

    // stricly only replace ' .' with '.'
    const withoutSpaces = withoutCitations.replace(/ \./g, '.');
    
    return withoutSpaces;
  };
  if (isLoading) {
    return (
      <div className="flex justify-center min-h-96 items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="flex justify-center min-h-96 items-center">
        <h2 className="text-center text-gray-200 text-2xl font-semibold">
          Oops... Error Loading Blog
        </h2>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="p-5 grid gap-5 max-w-[64rem]">
        <div className="flex gap-1 flex-col justify-center items-center">
          <h1 className="lg:text-6xl md:text-5xl sm:text-4xl text-3xl  text-center font-semibold">
            {data.title}
          </h1>

          <div className="flex items-center text-sm justify-center">
            <span className="text-gray-300  text-sm flex items-center justify-center gap-0">
              {new Date(data.created_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              <span className="text-gray-500 px-[0rem]">
                <Dot size={50} />
              </span>
              {new Date(data.created_at).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
            </span>
          </div>
        </div>
        <img
          className="object-cover w-full rounded-lg align-middle lg:h-96 lg:w-full md:h-96 md:w-full sm:h-96 sm:w-full"
          src={data.image_url}
        />
        <div className="flex justify-center mt-10 gap-5 ">
          {/**<section className="md:flex sticky h-fit top-10  flex-col items-center hidden">
            <ul className="text-gray-300 max-w-4xl">
              {slugs?.map((slug, i) => (
                <li
                  key={i}
                  className="px-2 py-2 text-gray-300 hover:text-white hover:cursor-pointer border-l"
                  onClick={() => scrollToSection(slug)}
                >
                  {i + 1}. {matches[i]}
                </li>
              ))}
            </ul>
          </section> **/}
          <div className="prose prose-invert prose-lg max-w-full">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                h1: ({node, ...props}) => (
                  <h1 className="text-4xl font-bold mb-6 text-white" {...props} />
                ),
                h2: ({node, ...props}) => (
                  <h2 className="text-3xl font-bold mt-8 mb-4 text-white" {...props} />
                ),
                strong: ({node, ...props}) => (
                  <strong className="text-white font-bold" {...props} />
                ),
                ul: ({node, ...props}) => (
                  <ul className="list-disc ml-6 mb-6 space-y-2" {...props} />
                ),
                li: ({node, ...props}) => (
                  <li className="text-gray-300" {...props} />
                ),
                p: ({node, ...props}) => (
                  <p className="text-gray-300 mb-4 leading-relaxed" {...props} />
                )
              }}
            >
              {cleanContent(data.content)}
            </ReactMarkdown>
          </div>
        </div>

        <div className="mt-16 p-8 bg-gradient-to-r from-gray-900 to-gray-800 rounded-lg border border-gray-700">
          <h2 className="text-2xl font-bold text-white mb-4">
            Want to do your own research?
          </h2>
          <p className="text-gray-300 mb-6">
            Get access to our AI-powered research tools and make informed investment decisions.
          </p>
          <Link 
            to="/contact-sales"
            className="inline-flex items-center px-6 py-3 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg transition-all duration-300"
          >
            Start Researching Now
          </Link>
        </div>
      </div>
    </div>
  );
};
export default BlogSingle;
