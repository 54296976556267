import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { supabase } from '../supabaseClient';

const ContactModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    businessEmail: '',
    fullName: '',
    mobileNumber: '',
    investorType: '',
    aum: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateBusinessEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.businessEmail) {
      newErrors.businessEmail = 'Email is required';
    } else if (!validateBusinessEmail(formData.businessEmail)) {
      newErrors.businessEmail = 'Please enter a valid email';
    }
    if (!formData.fullName) {
      newErrors.fullName = 'Full name is required';
    }
    if (!formData.mobileNumber) {
      newErrors.mobileNumber = 'Mobile number is required';
    } else if (!/^\+?[\d\s-]{8,}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = 'Please enter a valid mobile number';
    }
    if (!formData.investorType) {
      newErrors.investorType = 'Please select investor type';
    }
    if (formData.investorType === 'individual' && !formData.aum) {
      newErrors.aum = 'Please select your investment range';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from('sales_leads')
        .insert([
          {
            email: formData.businessEmail,
            full_name: formData.fullName,
            mobile_number: formData.mobileNumber,
            investor_type: formData.investorType,
            aum: formData.aum || null,
          },
        ]);

      if (error) throw error;
      setIsSubmitted(true);
      
      // Auto close after 3 seconds
      setTimeout(() => {
        onClose();
        // Reset form state
        setFormData({
          businessEmail: '',
          fullName: '',
          mobileNumber: '',
          investorType: '',
          aum: '',
        });
        setIsSubmitted(false);
      }, 3000);

    } catch (error) {
      console.error('Error submitting form:', error);
      setErrors({ submit: 'Failed to submit. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={!isSubmitting ? onClose : undefined}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          borderRadius: 2,
        }
      }}
    >
      {isSubmitted ? (
        // Success State
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <CheckCircleIcon sx={{ 
            fontSize: 64, 
            color: 'primary.main',
            mb: 2 
          }} />
          <Typography variant="h6" sx={{ mb: 2 }}>
            Thanks for submitting!
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Our team will reach out to you soon.
          </Typography>
        </Box>
      ) : (
        // Form State
        <>
          <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Contact Sales</Typography>
            {!isSubmitting && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{ color: 'grey.500' }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>

          <DialogContent dividers>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Our sales specialist will get in touch with you
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                required
                fullWidth
                label="Email Address"
                name="businessEmail"
                type="email"
                value={formData.businessEmail}
                onChange={handleChange}
                error={!!errors.businessEmail}
                helperText={errors.businessEmail}
                sx={{ mb: 2 }}
              />
              <TextField
                required
                fullWidth
                label="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
                sx={{ mb: 2 }}
              />
              <TextField
                required
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                error={!!errors.mobileNumber}
                helperText={errors.mobileNumber}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth error={!!errors.investorType} sx={{ mb: 2 }}>
                <InputLabel>Investor Type</InputLabel>
                <Select
                  name="investorType"
                  value={formData.investorType}
                  onChange={handleChange}
                  label="Investor Type"
                >
                  <MenuItem value="individual">Individual Investor</MenuItem>
                  <MenuItem value="institutional">Institutional Investor</MenuItem>
                </Select>
                {errors.investorType && (
                  <FormHelperText>{errors.investorType}</FormHelperText>
                )}
              </FormControl>

              {formData.investorType === 'individual' && (
                <FormControl fullWidth error={!!errors.aum} sx={{ mb: 2 }}>
                  <InputLabel>Investment Range</InputLabel>
                  <Select
                    name="aum"
                    value={formData.aum}
                    onChange={handleChange}
                    label="Investment Range"
                  >
                    <MenuItem value="0-10">0-10 Lacs</MenuItem>
                    <MenuItem value="10-30">10-30 Lacs</MenuItem>
                    <MenuItem value="30+">30+ Lacs</MenuItem>
                  </Select>
                  {errors.aum && (
                    <FormHelperText>{errors.aum}</FormHelperText>
                  )}
                </FormControl>
              )}
            </Box>
          </DialogContent>

          <DialogActions sx={{ p: 3 }}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={isSubmitting}
              sx={{
                py: 1.5,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ContactModal; 