export const sections = {
  forensics: {
    title: "Forensics",
    subsections: [
      {
        label: "Guidance Adherence",
        value: "guidance",
      },
      {
        label: "Financial Reporting Standards",
        value: "reporting",
      },
      {
        label: "Management Responses Check",
        value: "management",
      },
      {
        label: "Capital Allocation Strategies",
        value: "capital",
      },
      {
        label: "Operations & Strategies Execution",
        value: "operations",
      },
      {
        label: "Risk Management & External Factors",
        value: "risk",
      },
    ],
  },
  concall: {
    title: "Concall",
    value: "concall",
  }
}; 