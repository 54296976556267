import React, { Fragment, useState, useEffect } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../ui/dialog.tsx";
import { BookOpen, ExternalLink, Mic, LineChart, Cross, X } from "lucide-react";
import { Dialog as DialogR, Transition } from "@headlessui/react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
const getSourceIcon = (type) => {
  switch (type) {
    case "concall":
      return <Mic className="w-4 h-4" />;
    case "slide":
      return <LineChart className="w-4 h-4" />;
    case "announcement":
      return <ExternalLink className="w-4 h-4" />;
    default:
      return <BookOpen className="w-4 h-4" />;
  }
};
const API_URL = process.env.REACT_APP_API_URL || "https://compoundingai.in";

const getFormattedUrl = (content) => {
  if (!content) return "";

  if (content.includes("storage.googleapis.com")) {
    return content;
  }

  return `${API_URL}/${content.startsWith("/") ? content.slice(1) : content}`;
};

export const renderSourceMarkdown = (source) => {
  const icon = getSourceIcon(source.type);
  
  // Handle announcements differently
  if (source.type === "announcement") {
    const label = source.company || "Announcement";
    return (
      <DialogContent className="bg-neutral-900 border-neutral-800 font-terminal">
        <DialogHeader className="border-b border-neutral-800 pb-2">
          <DialogTitle>
            <div className="flex items-center gap-2 text-gray-200">
              <span className="text-gray-400">{icon}</span>
              <span className="font-medium">{label}</span>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <a 
            href={source.url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-300"
          >
            Open Announcement
          </a>
        </div>
      </DialogContent>
    );
  }

  // Existing code for other types
  const label = `${source.type === "concall" ? "Earnings Call" : "Presentation"} ${source.ticker || ""} ${source.quarter || ""} ${source.year || ""}`;
  if (source.type === "slide" || source.type === "Presentation") {
    const fullUrl = getFormattedUrl(source.content);
    return (
      <DialogContent className="bg-neutral-900 border-neutral-800 font-terminal min-h-[50svh] lg:max-w-[60svw]">
        <DialogHeader className="border-b border-neutral-800 pb-2">
          <DialogTitle>
            <div className="flex items-center gap-2 text-gray-200">
              <span className="text-gray-400">{icon}</span>
              <span className="font-medium">{label}</span>
            </div>
          </DialogTitle>
        </DialogHeader>
        <img
          src={fullUrl}
          alt={label}
          className="w-full h-full min-w-[40svw]  max-h-[80svh] object-fit"
        />
      </DialogContent>
    );
  }
  const transcriptContent = source.content ? source.content.replace(/.*Text:\s*/, "") : "";
  return (
    <DialogContent className="bg-neutral-900 border-neutral-800 font-terminal  lg:max-w-[60svw]  text-neutral-300 md:max-w-[80svw]">
      <DialogHeader className="border-b border-neutral-800 pb-2">
        <DialogTitle>
          <div className="flex items-center gap-2 text-gray-200">
            <span className="text-gray-400">{icon}</span>
            <span className="font-medium">{label}</span>
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="max-h-[60svh] overflow-y-auto">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <p className="mb-4" {...props} />,
            ul: ({ node, ...props }) => (
              <ul className="list-disc ml-4 mb-4" {...props} />
            ),
            ol: ({ node, ...props }) => (
              <ol className="list-decimal ml-4 mb-4" {...props} />
            ),
            li: ({ node, ...props }) => <li className="mb-2" {...props} />,
          }}
        >
          {transcriptContent}
        </ReactMarkdown>
      </div>
    </DialogContent>
  );
};

export const renderSourceContent = (source) => {
  const icon = getSourceIcon(source.type);
  
  // Handle announcements
  if (source.type === "announcement") {
    const label = source.company || "Announcement";
    return (
      <a
        href={source.url}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full"
      >
        <div className="flex items-center gap-2 text-sm text-neutral-200 hover:text-blue-400 transition-colors">
          <span className="text-gray-400">{icon}</span>
          <span className="flex flex-col items-start gap-1">
            <span className="font-medium text-start">{label}</span>
            <span className="text-xs text-start">[click to open]</span>
          </span>
        </div>
      </a>
    );
  }

  // Existing code remains unchanged
  const label = `${source.type === "concall" ? "Earnings Call" : "Presentation"} ${source.ticker || ""} ${source.quarter || ""} ${source.year || ""}`;
  
  if (source.type === "slide") {
    const fullUrl = getFormattedUrl(source.content);

    return (
      <Dialog>
        <DialogTrigger className="w-full rounded-none">
          <div className="flex flex-col justify-center">
            <img
              src={fullUrl}
              alt={label}
              className="w-full h-40 object-cover "
              onError={(e) => {
                e.target.onerror = null;
              }}
            />
            <div className="flex items-center gap-2 text-gray-200 mt-2 text-sm">
              <span className="text-gray-400">{icon}</span>
              <span className="font-medium text-start">{label}</span>
            </div>
          </div>
        </DialogTrigger>
        {renderSourceMarkdown(source)}
      </Dialog>
    );
  }

  return (
    <Dialog>
      <DialogTrigger className="rounded-none w-full h-full">
        <div className="flex items-center gap-2 text-sm text-neutral-200 strong h-full w-full">
          <span className="text-gray-400">{icon}</span>
          <span className="flex flex-col items-start gap-1">
            <span className="font-medium text-start">{label}</span>
            <span className="text-xs text-start">[click to read]</span>
          </span>
        </div>
      </DialogTrigger>
      {renderSourceMarkdown(source)}
    </Dialog>
  );
};



const MessageSources = ({ sources, messageId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [hoveredTranscript, setHoveredTranscript] = useState(null);

  const sourcesArray = React.useMemo(() => {
    if (!sources || typeof sources !== "object") {
      return [];
    }

    return Object.entries(sources)
      .map(([key, value]) => ({
        id: key,
        ...value,
      }))
      .filter(Boolean);
  }, [sources]);

  useEffect(() => {
    const handleSourceClick = (e) => {
      const citation = e.target.closest(
        `.source-${messageId}-${e.target.closest(".source-citation")?.dataset.source}`,
      );

      if (citation) {
        const sourceId = citation.dataset.source;
        const source = sources[sourceId];

        if (source) {
          if (source.type === "slide") {
            window.open(getFormattedUrl(source.content), "_blank");
          } else if (source.type === "concall") {
            const rect = citation.getBoundingClientRect();
            setTooltipPosition({
              top: rect.bottom + window.scrollY,
              left: rect.left + window.scrollX,
            });
            setHoveredTranscript(source.content.replace(/^Text: Text: /, ""));
          }
        }
      } else if (!e.target.closest(".transcript-tooltip")) {
        setHoveredTranscript(null);
        setTooltipPosition(null);
      }
    };

    setHoveredTranscript(null);
    setTooltipPosition(null);

    document.addEventListener("click", handleSourceClick);
    return () => {
      document.removeEventListener("click", handleSourceClick);
      setHoveredTranscript(null);
      setTooltipPosition(null);
    };
  }, [sources, messageId]);

  const TranscriptTooltip = () => {
    if (!hoveredTranscript || !tooltipPosition) return null;

    return (
      <div
        className="fixed z-50 p-4 bg-neutral-800 rounded-lg shadow-xl max-w-md max-h-60 overflow-y-auto transcript-tooltip border border-neutral-600"
        style={{
          top: `${tooltipPosition.top + 10}px`,
          left: `${tooltipPosition.left}px`,
        }}
      >
        <div className="text-sm text-neutral-200">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              p: ({ node, ...props }) => <p className="mb-4" {...props} />,
              ul: ({ node, ...props }) => (
                <ul className="list-disc ml-4 mb-4" {...props} />
              ),
              ol: ({ node, ...props }) => (
                <ol className="list-decimal ml-4 mb-4" {...props} />
              ),
              li: ({ node, ...props }) => <li className="mb-2" {...props} />,
            }}
          >
            {hoveredTranscript}
          </ReactMarkdown>
        </div>
      </div>
    );
  };

  if (!sourcesArray || sourcesArray.length === 0) return null;

  const getImagePath = (content) => {
    if (!content) return "";

    if (content.startsWith("sources/")) {
      return content;
    }
    return `sources/${content}`;
  };

  return (
    <>
      <div className="mt-4">
        <div className="flex items-center gap-2 mb-2">
          <BookOpen className="w-4 h-4 text-gray-400" />
          <span className="text-sm font-medium text-gray-400">Sources</span>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {sourcesArray.slice(0, 3).map((source) => (
            <div
              key={source.id}
              className="p-3 rounded-none bg-neutral-800 hover:bg-neutral-700 transition-colors border border-gray-700"
            >
              {renderSourceContent(source)}
            </div>
          ))}
        </div>

        {/* View More Sources Modal */}
        <Dialog>
          <DialogTrigger>
            <div className="mt-2 text-sm text-blue-400 hover:text-blue-300">
              View {sourcesArray.length - 3} more sources
            </div>
          </DialogTrigger>
          <DialogContent className="bg-neutral-900 border-neutral-800 font-terminal">
            <DialogHeader>
              <DialogTitle className="text-xl font-medium">
                {sourcesArray.length} Sources
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-400">
                {sourcesArray.length} sources for this message
              </DialogDescription>
              <div className="grid grid-cols-1 gap-2 max-h-[70svh] overflow-y-scroll">
                {sourcesArray.map((source) => (
                  <div
                    key={source.id}
                    className="p-3 rounded-none bg-neutral-800 border border-gray-700"
                  >
                    {renderSourceContent(source)}
                  </div>
                ))}
              </div>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default MessageSources;
