import React from 'react';
import { FileDown } from 'lucide-react';
import html2pdf from 'html2pdf.js';
import { marked } from 'marked';

const ExportPDF = ({ messages, title }) => {
  const generatePDF = async () => {
    // Create a container with white background
    const container = document.createElement('div');
    container.style.padding = '20px';
    container.style.backgroundColor = 'white';
    container.style.color = 'black';
    container.style.fontFamily = 'system-ui, -apple-system, sans-serif';
    container.style.width = '180mm';
    container.style.margin = '0 auto';
    container.style.boxSizing = 'border-box';
    container.style.maxWidth = '100%';

    // Add CompoundingAI branding at the top
    const brandingHeader = document.createElement('div');
    brandingHeader.style.marginBottom = '20px';
    brandingHeader.style.display = 'flex';
    brandingHeader.style.flexDirection = 'column';
    brandingHeader.style.gap = '4px';

    // Main heading with "Powered by" text
    const mainHeading = document.createElement('div');
    mainHeading.style.display = 'flex';
    mainHeading.style.alignItems = 'center';
    mainHeading.style.gap = '8px';
    
    const poweredByText = document.createElement('span');
    poweredByText.style.fontSize = '14px';
    poweredByText.style.color = '#6B7280';
    poweredByText.textContent = 'Powered by';

    const companyName = document.createElement('span');
    companyName.style.fontSize = '16px';
    companyName.style.fontWeight = '600';
    companyName.style.color = '#4F46E5';
    companyName.textContent = 'CompoundingAI';

    mainHeading.appendChild(poweredByText);
    mainHeading.appendChild(companyName);
    
    // Tagline
    const tagline = document.createElement('div');
    tagline.style.fontSize = '12px';
    tagline.style.color = '#6B7280';
    tagline.textContent = 'AI-Powered Research Assistant';
    
    // Motto
    const motto = document.createElement('div');
    motto.style.fontSize = '10px';
    motto.style.color = '#9CA3AF';
    motto.style.fontStyle = 'italic';
    motto.textContent = 'Maximizing shareholders value using AI';

    brandingHeader.appendChild(mainHeading);
    brandingHeader.appendChild(tagline);
    brandingHeader.appendChild(motto);
    container.appendChild(brandingHeader);

    // Add title section
    const titleSection = document.createElement('div');
    titleSection.style.marginBottom = '30px';
    titleSection.style.borderBottom = '2px solid #2563eb';
    titleSection.style.paddingBottom = '10px';
    titleSection.style.width = '100%';
    titleSection.style.maxWidth = '100%';

    const titleText = document.createElement('h1');
    titleText.style.fontSize = '24px';
    titleText.style.fontWeight = 'bold';
    titleText.style.color = '#1e293b';
    titleText.style.wordWrap = 'break-word';
    titleText.style.overflowWrap = 'break-word';
    titleText.style.lineHeight = '1.4';
    titleText.style.marginBottom = '8px';
    titleText.textContent = title;
    
    const timestamp = document.createElement('div');
    timestamp.style.fontSize = '12px';
    timestamp.style.color = '#64748b';
    timestamp.textContent = new Date().toLocaleString();

    titleSection.appendChild(titleText);
    titleSection.appendChild(timestamp);
    container.appendChild(titleSection);

    // Add messages with page break controls
    messages.forEach((message, index) => {
      const messageDiv = document.createElement('div');
      messageDiv.style.marginBottom = '20px';
      messageDiv.style.padding = '15px';
      messageDiv.style.borderRadius = '8px';
      messageDiv.style.backgroundColor = message.role === 'user' ? '#f1f5f9' : '#ffffff';
      messageDiv.style.border = '1px solid #e2e8f0';
      messageDiv.style.pageBreakInside = 'avoid';
      messageDiv.style.breakInside = 'avoid';
      messageDiv.style.width = '100%';
      messageDiv.style.maxWidth = '100%';
      messageDiv.style.wordWrap = 'break-word';
      messageDiv.style.overflowWrap = 'break-word';

      // Add role indicator with icon
      const roleDiv = document.createElement('div');
      roleDiv.style.marginBottom = '8px';
      roleDiv.style.fontWeight = '600';
      roleDiv.style.color = '#475569';
      roleDiv.style.display = 'flex';
      roleDiv.style.alignItems = 'center';
      roleDiv.style.gap = '8px';
      
      // Add icon based on role
      const icon = message.role === 'user' ? '👤' : '🤖';
      roleDiv.textContent = `${icon} ${message.role === 'user' ? 'You' : 'Assistant'}`;
      
      messageDiv.appendChild(roleDiv);

      // Add message content
      const contentDiv = document.createElement('div');
      contentDiv.style.whiteSpace = 'pre-wrap';
      contentDiv.style.fontSize = '14px';
      contentDiv.style.lineHeight = '1.6';
      contentDiv.style.color = '#1e293b';
      contentDiv.style.width = '100%';
      contentDiv.style.maxWidth = '100%';
      contentDiv.style.wordWrap = 'break-word';
      contentDiv.style.overflowWrap = 'break-word';
      contentDiv.style.paddingRight = '10px';

      // Configure marked options for GitHub-flavored markdown
      marked.setOptions({
        gfm: true,
        breaks: true,
        headerIds: false
      });

      // Convert markdown to HTML
      const htmlContent = marked(message.content);
      contentDiv.innerHTML = htmlContent;

      // Add styles for markdown elements
      contentDiv.style.cssText += `
        & h1, & h2, & h3, & h4, & h5, & h6 {
          margin-top: 2em;
          margin-bottom: 1em;
          page-break-after: avoid;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
          padding-right: 10px;
        }
        & p {
          margin-bottom: 1.5em;
          line-height: 1.8;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
          padding-right: 10px;
        }
        & ul, & ol {
          margin-top: 1em;
          margin-bottom: 1.5em;
          padding-left: 2em;
          padding-right: 10px;
          max-width: 100%;
        }
        & li {
          margin-bottom: 0.8em;
          line-height: 1.6;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
          padding-right: 10px;
        }
        & code {
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
        }
        & pre {
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 100%;
          padding-right: 10px;
        }
      `;

      messageDiv.appendChild(contentDiv);

      // Add page break control if it's the first message
      if (index === 0) {
        messageDiv.style.pageBreakBefore = 'avoid';
      }

      container.appendChild(messageDiv);
    });

    // Update footer style to ensure it appears on every page
    const footerStyle = `
      .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 10px;
        color: #6B7280;
        padding: 10px 0;
        border-top: 1px solid #E5E7EB;
        background-color: white;
      }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.textContent = footerStyle;
    container.appendChild(styleSheet);

    // Add a spacer div to prevent content from overlapping with footer
    const spacer = document.createElement('div');
    spacer.style.height = '50px'; // Match footer height
    container.appendChild(spacer);

    // Configure PDF options with improved settings
    const opt = {
      margin: [15, 15, 35, 15],
      filename: `${title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_chat.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        backgroundColor: '#ffffff',
        windowWidth: 794,
        letterRendering: true
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait',
        compress: false,
        putTotalPages: true,
        precision: 16
      },
      pagebreak: { 
        mode: ['avoid', 'css', 'legacy'],
        before: '.page-break-before',
        after: '.page-break-after',
        avoid: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li', 'p']
      },
      enableLinks: true,
      footer: {
        height: '28mm',
        contents: {
          default: `
            <div style="text-align: center; font-size: 10px; color: #6B7280; padding: 10px 0; border-top: 1px solid #E5E7EB; background-color: white;">
              Generated by CompoundingAI | {{page}} of {{pages}}
            </div>
          `
        }
      }
    };

    // Add content wrapper to preserve text selectability
    const contentWrapper = document.createElement('div');
    contentWrapper.style.position = 'relative';
    contentWrapper.style.zIndex = '1';
    container.appendChild(contentWrapper);

    // Move all content into the wrapper
    while (container.firstChild !== contentWrapper) {
      contentWrapper.appendChild(container.firstChild);
    }

    // Generate PDF with improved settings
    try {
      document.body.appendChild(container);
      const pdf = await html2pdf().set(opt).from(container).toPdf().get('pdf');
      
      // Enable text copying in the generated PDF
      pdf.setProperties({
        title: title,
        subject: 'CompoundingAI Generated Report',
        creator: 'CompoundingAI',
        keywords: 'CompoundingAI, Research, Analysis',
        printScaling: 'AppDefault'
      });
      
      await pdf.save();
      document.body.removeChild(container);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <button
      onClick={generatePDF}
      className="flex items-center gap-2 px-3 py-1.5 bg-neutral-800 hover:bg-neutral-700 rounded text-sm transition-colors"
      title="Export as PDF"
    >
      <FileDown size={16} />
      <span>Export PDF</span>
    </button>
  );
};

export default ExportPDF; 