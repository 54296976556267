import React, { useState, useEffect } from 'react';
import { useAuth } from "../../context/AuthContext";
import api from "../../config/axios";
import MetricsCard from './MetricsCard';
import UserSelector from './UserSelector';
import InteractionsTable from './InteractionsTable';
import { Alert, CircularProgress, Box } from '@mui/material';

function Dashboard() {
  const [isAdmin, setIsAdmin] = useState(null);
  const [aggregateMetrics, setAggregateMetrics] = useState(null);
  const [userMetrics, setUserMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');
  const { session } = useAuth();

  const fetchAggregateAnalytics = async () => {
    try {
      const response = await api.get('/admin/analytics/aggregate');
      setAggregateMetrics(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching aggregate analytics:', error);
      setError('Failed to fetch analytics data');
    }
  };

  const fetchUserAnalytics = async (userEmail) => {
    try {
      const response = await api.get('/admin/analytics/user', {
        params: { user_email: userEmail }
      });
      setUserMetrics(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching user analytics:', error);
      setError('Failed to fetch user analytics data');
    }
  };

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await api.get('/check-admin');
        setIsAdmin(response.data.is_admin);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };

    if (session) {
      checkAdmin();
    }
  }, [session]);

  useEffect(() => {
    if (isAdmin) {
      setLoading(true);
      if (selectedUser) {
        fetchUserAnalytics(selectedUser);
      } else {
        fetchAggregateAnalytics();
      }
      setLoading(false);
    }
  }, [isAdmin, selectedUser]);

  if (isAdmin === null) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAdmin) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Alert severity="error">You don't have permission to access this page</Alert>
      </Box>
    );
  }

  const metrics = selectedUser ? userMetrics : aggregateMetrics;

  return (
    <div className="p-6 pb-24 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Admin Dashboard</h1>
      
      {error && <Alert severity="error" className="mb-4">{error}</Alert>}
      
      <UserSelector 
        selectedUser={selectedUser} 
        onUserChange={setSelectedUser} 
      />

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <MetricsCard 
              title="Total Queries" 
              value={metrics?.total_queries || 0} 
            />
            <MetricsCard 
              title="Today's Queries" 
              value={metrics?.today_queries || 0} 
            />
            <MetricsCard 
              title="Avg Answer Length" 
              value={`${Math.round(metrics?.avg_answer_length || 0)} words`} 
            />
          </div>

          <InteractionsTable interactions={metrics?.interactions || []} />
        </>
      )}
    </div>
  );
}

export default Dashboard; 