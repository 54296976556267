import React from 'react';
import { Container, Typography, Box, Paper, List, ListItem } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #1a1a1a, #2d2d2d)',
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#6366F1',
  fontWeight: 600,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)({
  color: 'rgba(255, 255, 255, 0.8)',
  paddingLeft: 0,
});

const Privacy = () => {
  return (
    <Container maxWidth="lg">
      <StyledPaper>
        <Typography variant="h4" gutterBottom sx={{ color: '#6366F1', fontWeight: 700 }}>
          Privacy Policy
        </Typography>
        
        <Box sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
          <Typography paragraph>
            This privacy policy sets out how <strong>DIVIDENZ WEALTH PRIVATE LIMITED</strong> uses and protects any information that you give <strong>DIVIDENZ WEALTH PRIVATE LIMITED</strong> when you visit their website and/or agree to purchase from them.
          </Typography>

          <Typography paragraph>
            <strong>DIVIDENZ WEALTH PRIVATE LIMITED</strong> is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
          </Typography>

          <Typography paragraph>
            <strong>DIVIDENZ WEALTH PRIVATE LIMITED</strong> may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you adhere to these changes.
          </Typography>

          <SectionTitle variant="h5">
            Information We Collect
          </SectionTitle>
          <List>
            <StyledListItem>• Name</StyledListItem>
            <StyledListItem>• Contact information including email address</StyledListItem>
            <StyledListItem>• Demographic information such as postcode, preferences and interests, if required</StyledListItem>
            <StyledListItem>• Other information relevant to customer surveys and/or offers</StyledListItem>
          </List>

          <SectionTitle variant="h5">
            What We Do With The Information We Gather
          </SectionTitle>
          <List>
            <StyledListItem>• Internal record keeping</StyledListItem>
            <StyledListItem>• Improving our products and services</StyledListItem>
            <StyledListItem>• Sending promotional emails about new products, special offers or other information</StyledListItem>
            <StyledListItem>• Contacting you for market research purposes</StyledListItem>
            <StyledListItem>• Customizing the website according to your interests</StyledListItem>
          </List>

          <SectionTitle variant="h5">
            Security
          </SectionTitle>
          <Typography paragraph>
            We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.
          </Typography>

          <SectionTitle variant="h5">
            How We Use Cookies
          </SectionTitle>
          <Typography paragraph>
            A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
          </Typography>

          <Typography paragraph>
            We use traffic log cookies to identify which pages are being used. This helps us analyze data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
          </Typography>

          <SectionTitle variant="h5">
            Controlling Your Personal Information
          </SectionTitle>
          <Typography paragraph>
            You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at <strong>support@compoundingai.in</strong>
          </Typography>

          <SectionTitle variant="h5">
            Contact Us
          </SectionTitle>
          <Typography variant="body1" component="div">
            <strong>Merchant Legal entity name:</strong> DIVIDENZ WEALTH PRIVATE LIMITED<br />
            <strong>Registered Address:</strong> C-31 Tulsishyam Tenament opposite Novino Batteries Vadodara GUJARAT 390010<br />
            <strong>Operational Address:</strong> C-31 Tulsishyam Tenament opposite Novino Batteries Vadodara GUJARAT 390010<br />
            <strong>Telephone No:</strong> 8200860726<br />
            <strong>E-Mail ID:</strong> support@compoundingai.in
          </Typography>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default Privacy; 