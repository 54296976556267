import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import api from "../../config/axios";

function UserSelector({ selectedUser, onUserChange }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/admin/users');
        setUsers(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <FormControl fullWidth className="mb-6">
      <InputLabel>Select User</InputLabel>
      <Select
        value={selectedUser}
        label="Select User"
        onChange={(e) => onUserChange(e.target.value)}
      >
        <MenuItem value="">All Users</MenuItem>
        {users.map((user) => (
          <MenuItem key={user.email} value={user.email}>
            {user.email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default UserSelector; 